import React from 'react';
import styled from 'styled-components';

import dogYardImage from '../../../../../assets/images/dog-house/doghouse-yard.png';

const StyledDogYard = styled.image`
    overflow: visible;
`;

const dogYard = ( props ) => {
  return(
    <StyledDogYard
      id="dogYard"
      width="600"
      height="379"
      xlinkHref={dogYardImage}
      transform="matrix(1 0 0 1 0 221)"
    />
  );
}

export default dogYard;
