/*
 * House Image Tour Functional Component
 * This component displays the house image on the tour page.
 */

import React, { Component } from 'react';
import styled from 'styled-components';

import Spinner from '../../UI/Spinner/Spinner';
import Modal from '../../UI/Modal/Modal';


const StyledHouseImageTour = styled.img`
  max-width: 100px;
  height: 100%;
  max-height: 100px;
  opacity: 0;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.5s, transform 0.3s, box-shadow 0.5s;


  &:hover,
  &:focus {
    transform: scale(1.03);
    box-shadow: 0 2px 3px ${props => props.theme.colors.shadow};
    z-index: 3;
    position: relative;
    cursor: pointer;
  }

  &:active {
    transform: scale(1.08);
    box-shadow: 0 10px 10px ${props => props.theme.colors.shadow};
  }
`;

const StyledHouseImageContainer = styled.div`
  position: ${props => props.show ? 'relative' : ''};
  width: ${props => props.show ? '100px' : ''};
  height: ${props => props.show ? '100px' : ''};
  line-height: 0;
`;

const StyledSpinnerContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: ${props => props.show ? 'block' : 'none'};
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  width: 100vw;
  max-width: 50px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100vh;
  max-height: 50px;
  display: ${props => props.show ? 'block' : 'none'};
`;

const StyledHouseImageLarge = styled.img`
  width: 100%;
  object-fit: cover;
  max-width: 100%;
  margin: 0 auto;
  display: block;

  ${props => props.theme.media.medium`
    max-width: 80%;
  `}
`;

const StyledTourModal = styled(Modal)`
  top: 20%;
  padding: 1em;
  position: fixed;

  #shadow {
    display: none;
  }
`;

class HouseImageTour extends Component  {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      show: false,
      showModal: false,
    }
  }

  loadHandler = () => {
    this.setState({
      loading: false,
      show: true,
    });
  }

  imageClickHandler = () => {
    this.setState({ showModal: true });
  }

  closeModalHandler = () => {
    this.setState({showModal: false});
  }



  render() {
    return(
      <React.Fragment>
        <StyledHouseImageContainer show={this.state.loading}>
          <StyledTourModal
            show={this.state.showModal}
            modalClosed={this.closeModalHandler}
            top={this.props.offsetTop}>
            <StyledHouseImageLarge src={this.props.src} />
          </StyledTourModal>
          <StyledSpinner show={this.state.loading} />
          <StyledHouseImageTour
            src={this.props.src}
            onLoad={this.loadHandler}
            show={this.state.show}
            onClick={this.imageClickHandler} />
        </StyledHouseImageContainer>
      </React.Fragment>
    );
  }
}

export default HouseImageTour;
