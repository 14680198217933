import React from 'react';
import styled from 'styled-components';

import catWindowImage from '../../../../../assets/images/cat-house/cat-window.png';

const StyledCatWindow = styled.image`
  overflow: visible;
`;

const catWindow = ( props ) => {
  return(
    <StyledCatWindow
      id="catWindow"
      width="209"
      height="224"
      xlinkHref={catWindowImage}
      transform="matrix(1 0 0 1 303 244)"
    />
  );
}

export default catWindow;
