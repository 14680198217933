import React from 'react';
import styled from 'styled-components';

const StyledWindow = styled.svg`
  max-width: 30px;
  width: 100%;
  fill: ${props => props.theme.colors.darkBlue};
  transition: fill 0.3s;
`;

const window = ( props ) => {
  return(
    <StyledWindow viewBox="0 0 37.2 34.8">
      <path d="M34.5 31.3V0H2.8v31.3H0v2h1.6v1.4h34v-1.4h1.6v-2h-2.7zm-17-2.3h-12V17.2h12V29zm0-14.3h-12V2.9h12v11.8zM31.7 29h-12V17.2h12V29zm0-14.3h-12V2.9h12v11.8z"/>
    </StyledWindow>
  );
}

export default window;
