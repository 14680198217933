import React from 'react';
import styled from 'styled-components';

import dougYardImage from '../../../../../assets/images/doug-house/doug-yard.png';

const StyledDougYard = styled.image`
    overflow: visible;
`;

const dougYard = ( props ) => {
  return(
    <StyledDougYard
      id="dougYard"
      width="594"
      height="269"
      xlinkHref={dougYardImage}
      transform="matrix(1 0 0 1 0 331)"
    />
  );
}

export default dougYard;
