import React from 'react';
import styled from 'styled-components';

import NavigationItems from '../Navigations/NavigationItems';
import PostureLogo from '../../Logo/PostureLogo';

const StyledNavBar = styled.nav`
  background-color: ${props => props.theme.colors.orange};
  width: 100%;
`;

const StyledNavBarInner = styled.div`
  max-width: 75em;
  margin: 0 auto;

  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 1em;

  ${props => props.theme.media.medium`
    flex-flow: row;
    margin-bottom: 1em;
    padding-top: 0;
    align-items: flex-start;
  `}
`;

const navbar = ( props ) => {
  return(
    <StyledNavBar>
      <StyledNavBarInner>
        <PostureLogo />
        <NavigationItems
          progress={props.progress}
          items={props.items}
          clickedNavItem={props.clicked}/>
      </StyledNavBarInner>
    </StyledNavBar>
  );
}

export default navbar;
