import React from 'react';
import styled from 'styled-components';

import catDoorImage from '../../../../../assets/images/cat-house/cat-door-thumbnail.png';

const StyledCatDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const catDoorThumbnail = ( props ) => {
  return(
    <StyledCatDoorThumbnail
      id="catDoorThumbnail"
      src={catDoorImage}
    />
  );
}

export default catDoorThumbnail;
