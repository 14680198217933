import React from 'react';
import styled from 'styled-components';

import bluechipFoundationImage from '../../../../../assets/images/foundation/bluechip-foundation.png';


const StyledBluechipFoundation = styled.image`
  overflow: visible;
`;

const bluechipFoundation = ( props ) => {
  return(
    <StyledBluechipFoundation
      id="bluechipLogo"
      width="396"
      height="196"
      xlinkHref={bluechipFoundationImage}
      transform="matrix(0.48 0 0 0.48 372.96 470.4)"
    />
  );
}

export default bluechipFoundation;
