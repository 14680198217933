import React from 'react';
import styled from 'styled-components';

import crayolaLocationImage from '../../../../../assets/images/crayola-house/crayola-background.png';

const StyledCrayolaLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const crayolaLocation = ( props ) => {
  return(
    <StyledCrayolaLocation
      id="crayolaLocation"
      width="600"
      height="600"
      xlinkHref={crayolaLocationImage}
    />
  );
}

export default crayolaLocation;
