import React from 'react';
import styled from 'styled-components';

import postureRoofImage from '../../../../../assets/images/posture-house/posture-roof-thumbnail.png';

const StyledPostureRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const postureRoofThumbnail = ( props ) => {
  return(
    <StyledPostureRoofThumbnail
      id="postureRoofThumbnail"
      src={postureRoofImage}
    />
  );
}

export default postureRoofThumbnail;
