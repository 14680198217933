import React from 'react';
import styled from 'styled-components';

import ShareItem from './ShareItem';

const StyledShareItems = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const shareItems = ( props ) => {
  return(
    <StyledShareItems>
      {props.items.map((item) => {
        if (item.type === 'Twitter') {
          return <ShareItem
            key={item.id}
            as="a"
            type={item.type}
            clicked={() => props.clickedItem(item.type)}
            link={props.link}
            data-text={'Check out the house I made!'}
            data-hashtags={'posturehouseparty'}
            data-url={'https://posturehouseparty.com'}>
            <item.icon />
          </ShareItem>
        } else {
          return <ShareItem
            key={item.id}
            type={item.type}
            clicked={() => props.clickedItem(item.type)}>
            <item.icon />
          </ShareItem>
        }
      }
      )}
    </StyledShareItems>
  );
}

export default shareItems;
