import React from 'react';
import styled from 'styled-components';

import traditionalDoorImage from '../../../../../assets/images/traditional-house/traditional-door-thumbnail.png';

const StyledNoreasterDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const traditionalDoorThumbnail = ( props ) => {
  return(
    <StyledNoreasterDoorThumbnail
      id="traditionalDoorThumbnail"
      src={traditionalDoorImage}
    />
  );
}

export default traditionalDoorThumbnail;
