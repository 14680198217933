import React from 'react';
import styled from 'styled-components';

import dougWindowImage from '../../../../../assets/images/doug-house/doug-window.png';

const StyledDougWindow = styled.image`
  overflow: visible;
`;

const dougWindow = ( props ) => {
  return(
    <StyledDougWindow
      id="dougWindow"
      width="303"
      height="227"
      xlinkHref={dougWindowImage}
      transform="matrix(1 0 0 1 297 246)"
    />
  );
}

export default dougWindow;
