import React from 'react';
import styled from 'styled-components';

import catYardImage from '../../../../../assets/images/cat-house/cat-yard-thumbnail.png';


const StyledCatYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const catYardThumbnail = ( props ) => {
  return(
      <StyledCatYardThumbnail
        id="catYardThumbnail"
        src={catYardImage}
      />
  );
}

export default catYardThumbnail;
