/*
 * Tour Stateful Component
 * This Component controls the tour page and passes it's state down to the
 * components rendered on the page.
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import AWS from 'aws-sdk';

import NAV_MENU from '../../data/constants/NAV_MENU';
import Navbar from '../../components/Navigation/Navbar/Navbar';
import Button from '../../components/UI/Button/Button';
import HouseImageTour from '../../components/House/HouseImage/HouseImageTour';

const StyledTourSection = styled.section`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
`;

const StyledTourSectionInner = styled.div`
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  background-color: ${props => props.theme.colors.backdrop};

  ${props => props.theme.media.medium`
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background: none;
  `}
`;


const StyledLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 1em;
  box-sizing: border-box;
  justify-content: center;
  min-height: 60px;

  ${props => props.theme.media.large`
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: min-content;
    max-width: calc(40% - 1em);
    margin-right: auto;
    margin-bottom: 5em;
    margin-left: auto;
  `}
`;


const StyledTourWrapper = styled.div`
  padding: 2em 0;
  background-color: ${props => props.theme.colors.lightBlue};

  ${props => props.theme.media.large`
    background: none;
  `}
`;


const StyledHouseImages = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

class Tour extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDesktop: false,
      loading: true,
      navItems: NAV_MENU,
      navProgress: this.calculateNavProgress(),
      awsBucketName: 'posture-house-party',
      awsBucketRegion: 'us-west-2',
      awsIdentityPoolId: 'us-west-2:f66d7de9-ac6a-4500-905a-70b457b9a9d1',
      houseImages: [],
      awsURL: 'https://s3.us-west-2.amazonaws.com/posture-house-party/',
      houseImageStartingKey: '1544218808063_392_posture_house_party.png',
      loadMoreAvailability: true,
      loadAmount: 20,
    };
  }


  componentDidMount() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
    this.configureAWSSDK();
    this.loadImagesHandler();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeHandler = () => {
    this.setState({
      isDesktop: window.innerWidth >= 1024,
    });
  }

  loadImagesHandler = () => {
    this.getAWSImages();
  }

  calculateNavProgress() {
    const path = this.props.history.location.pathname;
    let progress = 0;

    switch(path) {
      case ('/build'):
        progress = (2 / 3) * 100;
      break;
      case ('/share'):
      case ('/tour'):
        progress = (3 / 3) * 100;
      break;
      default:
        progress = 0;
    }
    return progress;
  }

  configureAWSSDK() {
    AWS.config.update({
      region: this.state.awsBucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.state.awsIdentityPoolId,
      }),
    });
  }

  getAWSImages() {
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: {Bucket: this.state.awsBucketName}
    });

    s3.listObjectsV2({
      MaxKeys: this.state.loadAmount,
      StartAfter: this.state.houseImageStartingKey,
    }, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        if (data.KeyCount < this.state.loadAmount) {
          data.Contents.pop();
          data.Contents.pop();

          const oldImages = [
            ...this.state.houseImages
          ];

          this.setState({
            houseImages: [
              ...oldImages,
              ...data.Contents
            ],
            loadMoreAvailability: false,
          });

        } else {
          const newStartAfterKey = data.Contents[data.Contents.length - 1];
          const oldImages = [
            ...this.state.houseImages
          ];

          if (oldImages.length > 0) {
            this.setState({
              houseImageStartingKey: newStartAfterKey.Key,
              houseImages: [
                ...oldImages,
                ...data.Contents
              ],
            });
          } else {
            this.setState({
              houseImageStartingKey: newStartAfterKey.Key,
              houseImages: data.Contents
            });
          }
        }
      }
    });
  }

  render() {
    return(
      <StyledTourSection>
        <Helmet>
          <title>Tour The Houses</title>
        </Helmet>
        <Navbar
          progress={this.state.navProgress}
          items={this.state.navItems}
          clicked={this.navClickHandler}/>
        <StyledTourSectionInner>
          <div style={{width: '100%', 'textAlign': 'center'}}>
            <h2 style={{color: 'white' }}>Tour all the Houses!</h2>
            <p style={{color: 'white', padding: '1em', lineHeight: '1.6'}}>Thank you for coming to The Posture House Party! We are no longer taking submissions for new homes. The Team at Posture wishes you a Happy New Year.</p>
          </div>
          <StyledHouseImages>
            {this.state.houseImages.map(house => (
              <HouseImageTour
                key={house.Key}
                src={this.state.awsURL + house.Key}
              />
            ))}
          </StyledHouseImages>
          <SyledButtonWrapper>
            <Button
              margin="1em"
              clicked={this.loadImagesHandler}
              disabled={!this.state.loadMoreAvailability}>Load More</Button>
          </SyledButtonWrapper>
        </StyledTourSectionInner>
      </StyledTourSection>
    );
  }
}

export default Tour;
