import React from 'react';
import styled from 'styled-components';

import basicWindowImage from '../../../../../assets/images/basic-house/basic-window.png';

const StyledBasicWindow = styled.image`
  overflow: visible;
`;

const basicWindow = ( props ) => {
  return(
    <StyledBasicWindow
      id="basicWindow"
      width="210"
      height="223"
      xlinkHref={basicWindowImage}
      transform="matrix(1 0 0 1 302 246)"
    />
  );
}

export default basicWindow;
