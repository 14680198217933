import React from 'react';
import styled from 'styled-components';

import postureWindowImage from '../../../../../assets/images/posture-house/posture-window-thumbnail.png';

const StyledPostureWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PostureWindowThumbnail = ( props ) => {
  return(
      <StyledPostureWindowThumbnail
        id="postureWindow"
        src={postureWindowImage}
      />
  );
}

export default PostureWindowThumbnail;
