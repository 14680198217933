import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledNavigationItem = styled.li`
  z-index: 2;
`;

const StyledNavLink = styled(NavLink)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'azo-sans-uber', BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  letter-spacing: 0.1rem;
  transition: color 0.3s;
  position: relative;

    &:hover,
    &:focus {
      color: ${props => props.theme.colors.grayHover};
    }

    &:active {
      color: ${props => props.theme.colors.grayActive};
    }

    &.active {
      color: ${props => props.theme.colors.darkBlueAlternate};

      &:hover,
      &:focus {
        color: ${props => props.theme.colors.darkBlueAlternateHover};
      }

      &:active {
        color: ${props => props.theme.colors.darkBlueAlternateActive};
      }
    }
`;

const navigationItem = ( props ) => {
  return(
    <StyledNavigationItem>
      <StyledNavLink
        to={'/' + props.label}
        onClick={props.clicked}>{props.label}</StyledNavLink>
    </StyledNavigationItem>
  );
}

export default navigationItem;
