import React from 'react';
import styled from 'styled-components';

import candyWindowImage from '../../../../../assets/images/candyland-house/candyland-window-thumbnail.png';

const StyledCandyWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const candyWindowThumbnail = ( props ) => {
  return(
      <StyledCandyWindowThumbnail
        id="candyWindow"
        src={candyWindowImage}
      />
  );
}

export default candyWindowThumbnail;
