import React from 'react';
import styled from 'styled-components';

import hanukkahYardImage from '../../../../../assets/images/hanukkah-house/hanukkah-yard-thumbnail.png';


const StyledHanukkahYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const hanukkahYardThumbnail = ( props ) => {
  return(
      <StyledHanukkahYardThumbnail
        id="hanukkahYardThumbnail"
        src={hanukkahYardImage}
      />
  );
}

export default hanukkahYardThumbnail;
