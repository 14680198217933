import React from 'react';
import styled from 'styled-components';

import postureDoorImage from '../../../../../assets/images/posture-house/posture-door.png';


const StyledPostureDoor = styled.image`
  overflow: visible;
`;

const postureDoor = ( props ) => {
  return(
    <StyledPostureDoor
      id="postureDoor"
      width="187"
      height="390"
      xlinkHref={postureDoorImage}
      transform="matrix(1 0 0 1 129 80)"
    />
  );
}

export default postureDoor;
