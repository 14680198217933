import React from 'react';
import styled from 'styled-components';

import basicLocationImage from '../../../../../assets/images/basic-house/basic-background.png';

const StyledBasicLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const basicLocation = ( props ) => {
  return(
    <StyledBasicLocation
      id="basicLocation"
      width="600"
      height="600"
      xlinkHref={basicLocationImage}
    />
  );
}

export default basicLocation;
