import React from 'react';
import styled from 'styled-components';

import dogRoofImage from '../../../../../assets/images/dog-house/doghouse-roof.png';

const StyledDogRoof = styled.image`
  overflow: visible;
`;

const dogRoof = ( props ) => {
  return(
    <StyledDogRoof
      id="dogRoof"
      width="429"
      height="237"
      xlinkHref={dogRoofImage}
      transform="matrix(1 0 0 1 104 53)"
    />
  );
}

export default dogRoof;
