import React from 'react';
import styled from 'styled-components';

import dogBackground from '../../../../../assets/images/dog-house/doghouse-background-thumbnail.png';

const StyledDogLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dogLocationThumbnail = ( props ) => {
  return(
      <StyledDogLocationThumbnail
        id="dogLocationThumbnail"
        src={dogBackground}
      />
  );
}

export default dogLocationThumbnail;
