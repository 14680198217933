import React from 'react';
import styled from 'styled-components';

import catYardImage from '../../../../../assets/images/cat-house/cat-yard.png';

const StyledCatYard = styled.image`
    overflow: visible;
`;

const catYard = ( props ) => {
  return(
    <StyledCatYard
      id="catYard"
      width="588"
      height="303"
      xlinkHref={catYardImage}
      transform="matrix(1 0 0 1 5 297)"
    />
  );
}

export default catYard;
