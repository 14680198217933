import React from 'react';
import styled from 'styled-components';

import postureBackground from '../../../../../assets/images/posture-house/posture-background-thumbnail.png';

const StyledPostureLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const postureLocationThumbnail = ( props ) => {
  return(
      <StyledPostureLocationThumbnail
        id="postureLocationThumbnail"
        src={postureBackground}
      />
  );
}

export default postureLocationThumbnail;
