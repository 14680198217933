import React from 'react';
import styled from 'styled-components';

import workshopBackground from '../../../../../assets/images/workshop-house/workshop-background-thumbnail.png';

const StyledWorkshopLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const workshopLocationThumbnail = ( props ) => {
  return(
      <StyledWorkshopLocationThumbnail
        id="workshopLocationThumbnail"
        src={workshopBackground}
      />
  );
}

export default workshopLocationThumbnail;
