import React from 'react';
import styled from 'styled-components';
import Particles from 'react-particles-js';

import particlesConfig from '../../data/constants/particlesjs-config.json';

const StyledSnowParticles = styled(Particles)`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;



const snowParticles = ( props ) => {
  return(
    <StyledSnowParticles params={particlesConfig} />
  );
}

export default snowParticles;
