import React, { Component } from 'react';
import Typed from 'typed.js';
import styled from 'styled-components';


const StyledTypeWrap = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.black};

  .typed-cursor {
    opacity: 1;
    animation: ${props => props.theme.animations.typedjsBlink} 0.7s infinite;
    font-size: 1.5em;
  }

  ${props => props.theme.media.medium`
    .typed-cursor {
      font-size: 3em;  
    }
  `}
`;

const StyledTypeSpan = styled.span`
  font-size: 1.5em;
  font-weight: 700;

  ${props => props.theme.media.medium`
    font-size: 3em;
  `}
`;


class TypedComponent extends Component {
  componentDidMount() {
    // If you want to pass more options as props, simply add
    // your desired props to this destructuring assignment.
    const { strings } = this.props;
    // You can pass other options here, such as typing speed, back speed, etc.
    const options = {
    	strings: strings,
      typeSpeed: 50,
      backSpeed: 50
    };
    // this.el refers to the <span> in the render() method
    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
  	// Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.typed.destroy();
  }

  render() {
    return(
      <StyledTypeWrap>
        <StyledTypeSpan
          style={{ whiteSpace: 'pre' }}
          ref={(el) => { this.el = el; }}
        />
      </StyledTypeWrap>
    );
  }
}

export default TypedComponent;
