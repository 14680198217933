import React from 'react';
import styled from 'styled-components';

import crayolaWindowImage from '../../../../../assets/images/crayola-house/crayola-window-thumbnail.png';

const StyledCrayolaWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CrayolaWindowThumbnail = ( props ) => {
  return(
      <StyledCrayolaWindowThumbnail
        id="crayolaWindow"
        src={crayolaWindowImage}
      />
  );
}

export default CrayolaWindowThumbnail;
