import React from 'react';
import styled from 'styled-components';

import noreasterBackground from '../../../../../assets/images/noreaster-house/noreaster-background.png';

const StyledNoreasterLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const noreasterLocation = ( props ) => {
  return(
    <StyledNoreasterLocation
      id="noreasterLocation"
      width="600"
      height="600"
      xlinkHref={noreasterBackground}
    />
  );
}

export default noreasterLocation;
