import React from 'react';
import styled from 'styled-components';

import candyBackground from '../../../../../assets/images/candyland-house/candyland-background-thumbnail.png';

const StyledCandyLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const candyLocationThumbnail = ( props ) => {
  return(
      <StyledCandyLocationThumbnail
        id="candyLocationThumbnail"
        src={candyBackground}
      />
  );
}

export default candyLocationThumbnail;
