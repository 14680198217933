import React from 'react';
import styled from 'styled-components';

const StyledBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 400;
  left: 0;
  top: 0;
  background-color: ${props => props.primary ? props.theme.colors.backdrop : props.theme.colors.backdropWhite};
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const backdrop = ( props ) => {
  return (
    props.show ? <StyledBackdrop
      onClick={props.clicked}
      primary={props.background} /> : null
  );
};

export default backdrop;
