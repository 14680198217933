import React, { Component } from 'react';
import styled from 'styled-components';
import Backdrop from '../Backdrop/Backdrop';

const StyledModal = styled.div`
  position: absolute;
  z-index: 500;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  border: 1px solid ${props => props.theme.colors.gray};
  box-shadow: 1px 1px 1px ${props => props.theme.colors.black};
  padding: 1em 1em 0 1em;
  left: 0;
  right: 0;
  top: 4%;
  margin: 0 auto;
  max-width: 300px;;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  transform: ${props => props.show ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity: ${props => props.show ? '1' : '0'};
  display: ${props => props.show ? 'block' : 'none'};


  ${props => props.theme.media.largeSmall`
    max-width: 500px;
    width: 100%;
    left: calc(50% - 250px);
  `};

  ${props => props.theme.media.medium`
    max-width: 600px;
    top: 10%;
    width: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
  `};

  ${props => props.theme.media.large`
    max-width: 900px;
  `};

  ${props => props.theme.height.medium`
    top: 10%;
    max-width: 80%;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
  `};
`;

const StyledCloseIcon = styled.div`
  border-radius: 30px;
  border: 2px solid ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.white};
  position: absolute;
  right: -1em;
  top: -1em;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.3s;


  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.gray};
  }

  &::before {
    content: '';
    height: 80%;
    background-color: ${props => props.theme.colors.black};
    width: 2px;
    transform: rotate(45deg);
    position: absolute;
    left: 50%;
    margin-top: 10%;
  }

  &::after {
    content: '';
    height: 80%;
    background-color: ${props => props.theme.colors.black};
    width: 2px;
    transform: rotate(-45deg);
    position: absolute;
    left: 50%;
    margin-top: 10%;
  }
`;

const StyledModalInner = styled.div`
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;


  ${props => props.theme.media.medium`
    max-height: 100%;
  `};

  button {
    margin-bottom: 4em;
  }
`;

const StyledShadow = styled.div`
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: transparent;

  ${props => props.theme.media.medium`
    display: none;
  `}

  &::after {
    content: '';
    position: absolute;
    bottom: -61px;
    left: 0;
    right: 0;
    background-color: transparent;
    width: 100%;
    height: 10px;
    display: block;
    box-shadow: 0 2px 40px 70px rgba(0, 0, 0, 0.8);
  }
`;

class Modal extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop clicked={this.props.modalClosed} show={this.props.show} background />
        <StyledModal
          show={this.props.show}
          className={this.props.className} >
          <StyledCloseIcon onClick={this.props.modalClosed}/>
          <StyledModalInner>
            {this.props.children}
          </StyledModalInner>
          <StyledShadow id="shadow" />
        </StyledModal>
      </React.Fragment>

    );
  }
}

export default Modal;
