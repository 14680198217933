/*
 * SliderArrowPrev Functional Component
 * This component displays the SVG arrow for the slider.
 */

import React from 'react';
import styled from 'styled-components';

const StyledSliderArrowPrev = styled.svg`
  fill: ${props => props.theme.colors.darkBlue};
  width: 100%;
  height: 100%;
  max-width: 25px;
  max-height: 25px;
  transition: fill 0.3s;

  ${props => props.theme.media.largeSmall`
    max-width: 40px;
    max-height: 40px;
  `}
`;
const sliderArrowPrev = ( props ) => {
  return(
    <StyledSliderArrowPrev viewBox="0 0 36.2 67.2">
      <polygon className="st0" points="31.8,0 36,4.2 8.2,32 36.2,63.2 31.7,67.2 0,31.8 "/>
    </StyledSliderArrowPrev>
  );
}

export default sliderArrowPrev;
