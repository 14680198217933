import React from 'react';
import styled from 'styled-components';

import dogDoorImage from '../../../../../assets/images/dog-house/doghouse-door-thumbnail.png';

const StyledDogDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dogDoorThumbnail = ( props ) => {
  return(
    <StyledDogDoorThumbnail
      id="dogDoorThumbnail"
      src={dogDoorImage}
    />
  );
}

export default dogDoorThumbnail;
