import React from 'react';
import styled from 'styled-components';

import basicDoorImage from '../../../../../assets/images/basic-house/basic-door.png';


const StyledBasicDoor = styled.image`
  overflow: visible;
`;

const basicDoor = ( props ) => {
  return(
    <StyledBasicDoor
      id="basicDoor"
      width="180"
      height="389"
      xlinkHref={basicDoorImage}
      transform="matrix(1 0 0 1 128 82)"
    />
  );
}

export default basicDoor;
