import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';

const StyledPostureSpinner = styled.div`
  margin: 1em auto;
  font-size: 10px;
  position: relative;
  width: 100%;
  text-align: center;

  p {
    text-indent: -9999em;
  }

  img {
    width: 100%;
    max-width: 300px;
  }
`;



const postureSpinner = ( props ) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('../../../data/constants/lottie-data.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    }
  };

  return(
    <StyledPostureSpinner>
      <Lottie
        options={defaultOptions}
        height={300}
        width={300}
      />
      {props.children}
      <p>Loading...</p>
    </StyledPostureSpinner>
  );
}

export default postureSpinner;
