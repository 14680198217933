import React from 'react';
import styled from 'styled-components';

import noreasterRoofImage from '../../../../../assets/images/noreaster-house/noreaster-roof.png';

const StyledNoreasterRoof = styled.image`
  overflow: visible;
`;

const noreasterRoof = ( props ) => {
  return(
    <StyledNoreasterRoof
      id="noreasterRoof"
      width="419"
      height="188"
      xlinkHref={noreasterRoofImage}
      transform="matrix(1 0 0 1 107 70)"
    />
  );
}

export default noreasterRoof;
