import React from 'react';
import styled from 'styled-components';

import candyRoofImage from '../../../../../assets/images/candyland-house/candyland-roof-thumbnail.png';

const StyledCandyRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const candyRoofThumbnail = ( props ) => {
  return(
      <StyledCandyRoofThumbnail
        id="candyRoofThumbnail"
        src={candyRoofImage}
      />
  );
}

export default candyRoofThumbnail;
