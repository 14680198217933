import React from 'react';
import styled from 'styled-components';

import postureWindowImage from '../../../../../assets/images/posture-house/posture-window.png';

const StyledPostureWindow = styled.image`
  overflow: visible;
`;

const postureWindow = ( props ) => {
  return(
    <StyledPostureWindow
      id="postureWindow"
      width="320"
      height="225"
      xlinkHref={postureWindowImage}
      transform="matrix(1 0 0 1 196 244)"
    />
  );
}

export default postureWindow;
