import React from 'react';
import styled from 'styled-components';

const StyledPostureHousePartyLogo = styled.svg`
  width: 80%;


  ${props => props.theme.media.large`
    width: 100%;
  `}

  .st0 {
    fill: ${props => props.theme.colors.white};
  }

  .st1 {
    fill: ${props => props.theme.colors.orange};
  }
`;
const postureHousePartyLogo = ( props ) => {
  return(
    <StyledPostureHousePartyLogo viewBox="0 0 397 220.1">
      <g id="Group_31" transform="translate(0 1.191)">
        <path className="st0" d="M81.7 47.3v-2.7H47.3v23.6H37.1V47.3h-2.8v-2.7H0V124h2.7v2.7h34.4V98.8h10.2V124h2.8v2.7h34.3V47.3z"/>
        <path className="st1" d="M1.4 46h31.5v23.7h15.9V46h31.5v76.6H48.8v-28H32.9v27.9H1.4V46z" id="Path_56"/>
      </g>
      <g id="Group_32" transform="translate(86.46)">
        <path className="st0" d="M78.2 59.1c-8-9-20.2-14.6-34-14.6C19.4 44.6 0 62.5 0 85.4c0 11.5 4.8 21.7 12.8 29 8 9 20.2 14.6 34 14.6C71.6 129 91 111 91 88.1c0-11.4-4.9-21.6-12.8-29zM36.4 88.7c0-5.8 4.7-10.4 10.4-10.4 2.2 0 4.2.7 5.9 1.8 1.2 1.7 1.8 3.7 1.8 5.9 0 5.8-4.7 10.4-10.5 10.4-2.2 0-4.2-.7-5.8-1.8-1.1-1.6-1.8-3.7-1.8-5.9z"/>
        <path className="st1" d="M44.2 46.1c24 0 42.7 17.2 42.7 39.4s-18.7 39.4-42.7 39.4S1.4 107.7 1.4 85.5s18.7-39.4 42.8-39.4zm0 51.7C50.7 97.8 56 92.5 56 86c0-6.6-5.3-11.9-11.9-11.9-6.6 0-11.9 5.3-11.9 11.9.1 6.5 5.4 11.8 12 11.8-.1 0-.1 0 0 0z" id="Path_58"/>
      </g>
      <g id="Group_33" transform="translate(179.398 1.191)">
        <path className="st0" d="M81.7 47.3v-2.7H50.5v41.2c0 7.6-2.3 10.7-8 10.7-1.6 0-3-.3-4.1-.9-.9-1.7-1.4-4.1-1.4-7.2V47.3h-2.7v-2.7H0v43.2c0 10.7 2.5 18.8 7.5 24.7.9 1.1 1.9 2 3 3 6.9 7.9 18.8 12.4 32.9 12.4 14.7 0 27-4.7 33.8-12.9 4.8-5.9 7.3-14.2 7.3-24.9V47.3h-2.8z"/>
        <path className="st1" d="M1.4 87.8V46h31.5v39.6c0 7.2 2.3 12.2 9.6 12.2 7 0 9.4-4.4 9.4-12.1V46h28.3v41.3c0 11.7-3 19.2-7 24-7 8.5-19.7 12.4-32.7 12.4-13.4 0-25.2-4.2-32-12.1-3.7-4.5-7.1-11.3-7.1-23.8z" id="Path_60"/>
      </g>
      <g id="Group_34" transform="translate(265.381 .048)">
        <path className="st0" d="M60.9 85.6c-1-1.2-2.1-2.3-3.4-3.3-6.3-4.9-14.6-6.7-19.1-7.7.4-.1.8-.1 1.1-.1 3.8 0 12.8 1.8 22.5 6.6l2 1V53.9l-.8-.4c-.6-.3-1.3-.6-2-.9v-1.4l-.8-.4c-7.1-3.7-18.4-6.2-27.9-6.2C12.8 44.6 0 55.2 0 71.8c0 7.1 2.4 12.7 7.2 17.1.2.1.3.3.5.4.7.8 1.4 1.6 2.2 2.3 6.5 5.7 15 7.6 19.9 8.7-.3 0-.6.1-1 .1-4.3 0-14.4-2.4-24.4-8.9L2.3 90v29.5l.8.4c.6.3 1.3.6 2 .9v1.4l.8.4c7.4 3.9 19 6.4 29.3 6.4 20.2 0 33.3-10.3 33.3-26.4-.1-7-2.5-12.6-7.6-17z"/>
        <path className="st1" d="M3.7 92.6c10.2 6.7 20.6 9.1 25.2 9.1 3.2 0 3.9-1.3 3.9-2.5 0-1.5-1-2.1-2.3-2.4-3.7-1-14.4-2.1-22.2-9-4-3.6-6.8-8.6-6.8-16C1.5 56.5 13.3 46 32.7 46 42 46 53 48.4 60 52v25.1c-10-5-19.2-6.8-23.2-6.8-3 0-3.9 1.1-3.9 2.3 0 1.6 1.1 2.2 2.4 2.6 3.2 1 13.7 2.2 21.2 8.1 5.1 3.9 7.9 10.1 7.7 16.5 0 14.7-11.9 25-31.9 25-9.6 0-21.2-2.3-28.6-6.2v-26z" id="Path_62"/>
      </g>
      <g id="Group_35" transform="translate(334.882 1.191)">
        <path className="st0" d="M59.4 99.5v-2.7h-3.3V77h-2.7v-1.3h7.2V47.3h-2.7v-2.7H0V124h2.7v2.7h59.4V99.5z"/>
        <path className="st1" d="M1.4 46h55.1v25.6H32.9v4.1h19v18.2h-19v4.3H58v24.3H1.4V46z" id="Path_64"/>
      </g>
      <g id="Group_36" transform="translate(0 92.224)">
        <path className="st0" d="M65.1 56.2c-6.5-7.6-18.3-11.6-34.7-11.6H0V124h2.7v2.7H37v-21.8c10.2 0 19-2.3 25.2-6.7 7.3-5 11.2-12.8 11.2-22.3.1-8.3-2.8-14.9-8.3-19.7zM40.3 74.9c0 2.2-1.1 3.8-3.2 4.5v-6.8c1.1 0 2.1.3 2.9.6.1.5.3 1 .3 1.7z"/>
        <path className="st1" d="M1.4 46h29c18.4 0 39 5.3 39 27.2 0 19.7-16.5 27.6-35.4 27.6h-1v21.8H1.4V46zm32.4 35.3c5.4 0 7.9-2.9 7.9-6.4s-2.6-6.4-7.9-6.4h-.9v12.9l.9-.1z" id="Path_66"/>
      </g>
      <g id="Group_37" transform="translate(59.545 92.224)">
        <path className="st0" d="M95.3 126.7L65 47.3h-1.6l-1-2.7H30.2L0 124h3.7l-1 2.7h34.4l3-10.1h13.4l1.9 7.3h2l.7 2.7h37.2z"/>
        <path className="st1" d="M31.2 46h30.1l29.2 76.6H56.4l-2.6-10.1H36.3l-2.9 10.1H2L31.2 46zm17.6 47.3l-3.4-15.5h-.2l-3.4 15.5h7z" id="Path_68"/>
      </g>
      <g id="Group_38" transform="translate(153.96 92.224)">
        <path className="st0" d="M89 91.9c-.1.1-.3.2-.4.3v-4.7l-2.3 1.7c-4.4 3.3-9.6 5.1-15.1 5.2-2.3 0-4.2-.5-5.7-1.3-.2-.4-.4-.8-.6-1.3 5.8-4.6 8.7-11 8.7-19.1 0-7-2.5-12.6-7.4-16.8-2.2-2.6-5.1-4.8-8.7-6.6-8.1-3.9-18.1-4.7-25.2-4.7H0V124h2.7v2.7H37v-16.4c1.5 2.2 3.2 4.1 5.1 5.8 6.4 7.4 16.1 11.7 27.4 11.7 7.7 0 15.7-2.2 21.2-5.7l.6-.4V90.2L89 91.9zm-48-18c0 2.4-1.4 3.9-4 4.3v-6h.9c1.1 0 2.1.2 2.9.5.1.4.2.8.2 1.2z"/>
        <path className="st1" d="M1.4 46h30.9c15.9 0 37.2 4.1 37.2 24 0 8.5-3.4 14.5-8.9 18.5 1 4.1 4.1 7.3 10.6 7.3 5.8-.1 11.3-2 16-5.5v27.9c-4.7 3-12.4 5.5-20.3 5.5-18.1 0-30.7-11.3-33.7-26.5H33v25.4H1.4V46zm33.8 33.8c5 0 7.2-2.6 7.2-5.9s-2.3-5.8-7.2-5.8h-2.3v11.7h2.3z" id="Path_70"/>
      </g>
      <g id="Group_39" transform="translate(228.654 92.224)">
        <path className="st0" d="M77.1 47.3v-2.7H0v28.8h2.7v2.8h18.7V124h2.7v2.7h34.3V76.2h21.4V47.3z"/>
        <path className="st1" d="M22.8 72H1.4V46h74.2v26H54.3v50.5H22.8V72z" id="Path_72"/>
      </g>
      <g id="Group_40" transform="translate(297.774 92.224)">
        <path className="st0" d="M91.9 47.3l1-2.8H59.7l-6.1 21-6.8-18.2h-1.6l-1-2.8h-36l23.9 49.6c-1.9.6-3.9.8-5.9.8-7.9 0-15.5-2.5-24-7.7L0 85.9v32.6l.7.4c.6.4 1.3.7 2 1.1v1.2l.7.4c6.4 3.9 17.1 6.2 27.9 6.2 22.5 0 37.9-10.5 45.6-31.1l18.7-49.4h-3.7z"/>
        <path className="st1" d="M1.4 88.5c7.3 4.5 15.4 7.9 24.7 7.9 2.7.1 5.4-.4 7.8-1.5L10.4 46h32.8L53 72.2h.2L60.8 46h30.1L73 93.4c-9.2 24.3-27.7 30.2-44.4 30.2-10 0-20.6-2.1-27.2-6V88.5z" id="Path_74"/>
      </g>
      <path className="st0" d="M13.1 11.1H4.4V.5h30.2v10.6h-8.7v20.6H13.1V11.1zM43.1.5H56v9.6h6.5V.5h12.8v31.1H62.4V20.3H56v11.4H43.1V.5zM84.2.5h22.4v10.4H97v1.7h7.7V20H97v1.8h10.2v9.9h-23V.5zM133.6.5h11.8c7.5 0 15.9 2.2 15.9 11.1 0 8-6.7 11.2-14.4 11.2h-.4v8.9h-12.8V.5zm13.1 14.3c2.2 0 3.2-1.2 3.2-2.6s-1.1-2.6-3.2-2.6h-.3v5.2h.3zM185.8 0c9.8 0 17.4 7 17.4 16s-7.6 16-17.4 16c-9.8 0-17.4-7-17.4-16s7.7-16 17.4-16zm0 21.1c2.7 0 4.8-2.1 4.8-4.8s-2.2-4.8-4.8-4.8c-2.7 0-4.8 2.2-4.8 4.8s2.1 4.8 4.8 4.8zM211.5 19c4.2 2.7 8.4 3.7 10.2 3.7 1.3 0 1.6-.5 1.6-1 0-.6-.4-.9-.9-1-1.5-.4-5.8-.9-9-3.7-1.6-1.5-2.8-3.5-2.8-6.5 0-6.2 4.8-10.5 12.7-10.5 3.8 0 8.3 1 11.1 2.5v10.2c-4-2-7.8-2.8-9.4-2.8-1.2 0-1.6.5-1.6.9 0 .6.5.9 1 1.1 1.3.4 5.6.9 8.6 3.3 2.1 1.7 3.1 3.9 3.1 6.7 0 6-4.8 10.1-13 10.1-3.9 0-8.6-.9-11.7-2.5V19zM250.9 11.1h-8.7V.5h30.2v10.6h-8.7v20.6h-12.8V11.1zM280.8 17.5V.5h12.8v16.1c0 2.9.9 5 3.9 5 2.8 0 3.8-1.8 3.8-4.9V.5h11.5v16.8c0 4.8-1.2 7.8-2.8 9.8-2.8 3.4-8 5-13.3 5-5.5 0-10.3-1.7-13-4.9-1.5-1.9-2.9-4.7-2.9-9.7zM321.8.5h12.8c6.4 0 15.1 1.6 15.1 9.9 0 3.4-1.6 5.8-3.9 7.4l7.1 13.8h-13.5l-4.6-10.2h-.2v10.2h-12.8V.5zm13.8 13.7c2 0 2.9-1.1 2.9-2.4s-.9-2.4-2.9-2.4h-.9v4.8h.9zM359.4.5h22.4v10.4h-9.6v1.7h7.7V20h-7.7v1.8h10.2v9.9h-23V.5z"/>
    </StyledPostureHousePartyLogo>
  );
}

export default postureHousePartyLogo;
