import React from 'react';
import styled from 'styled-components';

import dogWindowImage from '../../../../../assets/images/dog-house/doghouse-window-thumbnail.png';

const StyledDogWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dogWindowThumbnail = ( props ) => {
  return(
      <StyledDogWindowThumbnail
        id="dogWindow"
        src={dogWindowImage}
      />
  );
}

export default dogWindowThumbnail;
