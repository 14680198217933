import React from 'react';
import styled from 'styled-components';

import hanukkahRoofImage from '../../../../../assets/images/hanukkah-house/hanukkah-roof.png';

const StyledHanukkahRoof = styled.image`
  overflow: visible;
`;

const hanukkahRoof = ( props ) => {
  return(
    <StyledHanukkahRoof
      id="hanukkahRoof"
      width="449"
      height="195"
      xlinkHref={hanukkahRoofImage}
      transform="matrix(1 0 0 1 75 60)"
    />
  );
}

export default hanukkahRoof;
