import React from 'react';
import styled from 'styled-components';

import catLocationImage from '../../../../../assets/images/cat-house/cat-background.png';

const StyledCatLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const catLocation = ( props ) => {
  return(
    <StyledCatLocation
      id="catLocation"
      width="600"
      height="600"
      xlinkHref={catLocationImage}
    />
  );
}

export default catLocation;
