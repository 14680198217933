import React from 'react';
import styled from 'styled-components';

import noreasterRoofImage from '../../../../../assets/images/noreaster-house/noreaster-roof-thumbnail.png';

const StyledNoreasterRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const noreasterRoofThumbnail = ( props ) => {
  return(
      <StyledNoreasterRoofThumbnail
        id="noreasterRoofThumbnail"
        src={noreasterRoofImage}
      />
  );
}

export default noreasterRoofThumbnail;
