import React from 'react';
import styled from 'styled-components';

import hanukkahRoofImage from '../../../../../assets/images/hanukkah-house/hanukkah-roof-thumbnail.png';

const StyledHanukkahRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const hanukkahRoofThumbnail = ( props ) => {
  return(
    <StyledHanukkahRoofThumbnail
      id="hanukkahRoofThumbnail"
      src={hanukkahRoofImage}
    />
  );
}

export default hanukkahRoofThumbnail;
