import React from 'react';
import styled from 'styled-components';

import traditionalBackground from '../../../../../assets/images/traditional-house/traditional-background-thumbnail.png';

const StyledTraditionalLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const traditionalLocationThumbnail = ( props ) => {
  return(
      <StyledTraditionalLocationThumbnail
        id="traditionalLocationThumbnail"
        src={traditionalBackground}
      />
  );
}

export default traditionalLocationThumbnail;
