import React from 'react';
import styled from 'styled-components';

import HouseControl from './HouseControl/HouseControl';


const StyledHouseControls = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.lightBlue};
  display: inline-flex;
  justify-content: space-around;
`;

const houseControls = ( props ) => {
  return(
    <StyledHouseControls>
      {props.menu.map(control => (
        <HouseControl
          key={control.label}
          label={control.label}
          icon={control.icon}
          clickedItem={() => props.clickedItem(control.label)}
          activeItem={(props.activeItem === control.label) ? props.activeItem : null}>
          <control.icon />
        </HouseControl>
      ))}
    </StyledHouseControls>
  );
}

export default houseControls;
