/*
 * Nav Menu Constants Object
 */

const NAV_MENU = [
  {
    id: 0,
    label: 'watch',
  },
  {
    id: 1,
    label: 'build',
  },
  {
    id: 2,
    label: 'share',
  }
];

export default NAV_MENU;
