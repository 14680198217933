/*
 * SliderArrowNext Functional Component
 * This component displays the SVG arrow for the slider.
 */

import React from 'react';
import styled from 'styled-components';

const StyledSliderArrowNext = styled.svg`
  fill: ${props => props.theme.colors.darkBlue};
  width: 100%;
  height: 100%;
  max-width: 25px;
  max-height: 25px;
  transition: fill 0.3s;

  ${props => props.theme.media.largeSmall`
    max-width: 40px;
    max-height: 40px;
  `}
`;
const sliderArrowNext = ( props ) => {
  return(
    <StyledSliderArrowNext viewBox="0 0 36.16 67.22">
      <polygon className="st0" points="4.36,67.22 0.11,62.98 27.91,35.19 0,4 4.47,0 36.16,35.42 "/>
    </StyledSliderArrowNext>
  );
}

export default sliderArrowNext;
