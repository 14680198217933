import React from 'react';
import styled from 'styled-components';

import NavigationItem from './NavigationItem';

const StyledNavigationItems = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  padding: 0.2em 0;
  box-sizing: border-box;
  position: relative;

  ${props => props.theme.media.medium`
    width: 100%;
    height: 100%;
    line-height: 2;
    border-top: 0;


    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 20px;
      background-color: ${props => props.theme.colors.orange};
      z-index: 2;
      clip-path: polygon(100% 0, 0 100%, 0 0);
    }
  `}
`;

const StyledProgressBar = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;

  span {
    display: block;
    height: 100%;
    background-color: ${props => props.theme.colors.orangeHover};
    position: relative;
    width: ${props => props.progress}%;
  }

  span::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
      );
      z-index: 1;
      background-size: 50px 50px;
      animation: ${props => props.theme.animations.moveProgress} 2s linear infinite;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: hidden;
  }

  span::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 20px;
    background-color: ${props => props.theme.colors.orange};
    z-index: 2;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
  }
`;

const navigationItems = ( props ) => {
  return(
    <StyledNavigationItems>
      <StyledProgressBar progress={props.progress}>
        <span></span>
      </StyledProgressBar>
      {props.items.map((item) => (
        <NavigationItem
          key={item.label}
          label={item.label}
          clicked={(event) => props.clickedNavItem(item.label, event)}/>
      ))}
    </StyledNavigationItems>
  );
}

export default navigationItems;
