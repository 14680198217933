import React from 'react';
import styled from 'styled-components';

import dougYardImage from '../../../../../assets/images/doug-house/doug-yard-thumbnail.png';


const StyledDougYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dougYardThumbnail = ( props ) => {
  return(
      <StyledDougYardThumbnail
        id="dougYardThumbnail"
        src={dougYardImage}
      />
  );
}

export default dougYardThumbnail;
