import React from 'react';
import styled from 'styled-components';

import tropicalRoofImage from '../../../../../assets/images/tropical-house/tropical-roof.png';

const StyledTropicalRoof = styled.image`
  overflow: visible;
`;

const tropicalRoof = ( props ) => {
  return(
    <StyledTropicalRoof
      id="tropicalRoof"
      width="516"
      height="211"
      xlinkHref={tropicalRoofImage}
      transform="matrix(1 0 0 1 55 59)"
    />
  );
}

export default tropicalRoof;
