import React, { Component } from 'react';
import PropTypes from 'prop-types';


import BasicLocation from './HouseParts/Locations/BasicLocation';
import CandyLocation from './HouseParts/Locations/CandyLocation';
import DogLocation from './HouseParts/Locations/DogLocation';
import NoreasterLocation from './HouseParts/Locations/NoreasterLocation';
import TropicalLocation from './HouseParts/Locations/TropicalLocation';
import TraditionalLocation from './HouseParts/Locations/TraditionalLocation';
import WorkshopLocation from './HouseParts/Locations/WorkshopLocation';
import CrayolaLocation from './HouseParts/Locations/CrayolaLocation';
import CatLocation from './HouseParts/Locations/CatLocation';
import PostureLocation from './HouseParts/Locations/PostureLocation';
import HanukkahLocation from './HouseParts/Locations/HanukkahLocation';
import DougLocation from './HouseParts/Locations/DougLocation';




class HousePartLocation extends Component {
  render() {
    let part = null;

    switch(this.props.type) {
      case('basic-location'):
        part = <BasicLocation />;
        break;
      case('candy-location'):
        part = <CandyLocation />;
        break;
      case('dog-location'):
        part = <DogLocation />;
        break;
      case('noreaster-location'):
        part = <NoreasterLocation />;
        break;
      case('tropical-location'):
        part = <TropicalLocation />;
        break;
      case('traditional-location'):
        part = <TraditionalLocation />;
        break;
      case('workshop-location'):
        part = <WorkshopLocation />;
        break;
      case('crayola-location'):
        part = <CrayolaLocation />;
        break;
      case('cat-location'):
        part = <CatLocation />;
        break;
      case('posture-location'):
        part = <PostureLocation />;
        break;
      case('hanukkah-location'):
        part = <HanukkahLocation />;
        break;
      case('doug-location'):
        part = <DougLocation />;
        break;
      default:
        part = null;
    }

    return part;
  }
}

HousePartLocation.propTypes = {
  type: PropTypes.string.isRequired,
}

export default HousePartLocation;
