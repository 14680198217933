import React from 'react';
import styled from 'styled-components';

import hanukkahWindowImage from '../../../../../assets/images/hanukkah-house/hanukkah-window.png';

const StyledHanukkahWindow = styled.image`
  overflow: visible;
`;

const hanukkahWindow = ( props ) => {
  return(
    <StyledHanukkahWindow
      id="hanukkahWindow"
      width="216"
      height="230"
      xlinkHref={hanukkahWindowImage}
      transform="matrix(1 0 0 1 303 238)"
    />
  );
}

export default hanukkahWindow;
