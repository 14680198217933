import React from 'react';
import styled from 'styled-components';

import workshopWindowImage from '../../../../../assets/images/workshop-house/workshop-window.png';

const StyledWorkshopWindow = styled.image`
  overflow: visible;
`;

const workshopWindow = ( props ) => {
  return(
    <StyledWorkshopWindow
      id="workshopWindow"
      width="209"
      height="225"
      xlinkHref={workshopWindowImage}
      transform="matrix(1 0 0 1 303 245)"
    />
  );
}

export default workshopWindow;
