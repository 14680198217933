import React from 'react';
import styled from 'styled-components';

import candyDoorImage from '../../../../../assets/images/candyland-house/candyland-door.png';


const StyledCandyDoor = styled.image`
  overflow: visible;
`;

const candyDoor = ( props ) => {
  return(
    <StyledCandyDoor
      id="candyDoor"
      width="183"
      height="394"
      xlinkHref={candyDoorImage}
      transform="matrix(1 0 0 1 127 81)"
    />
  );
}

export default candyDoor;
