import React from 'react';
import styled from 'styled-components';

const StyledYard = styled.svg`
  max-width: 24px;
  width: 100%;
  fill: ${props => props.theme.colors.darkBlue};
  transition: fill 0.3s;
`;

const yard = ( props ) => {
  return(
    <StyledYard viewBox="0 0 37.42 53.48">
      <path d="M29.43,39.64h4.59l-6.39-11.08h3.6l-6.26-10.84l-0.18-0.31h3.98L23.73,8.7L18.71,0l-5.03,8.7l-5.02,8.71h3.97
      l-0.19,0.31L6.2,28.56h3.6l-6.4,11.08h4.6L0,53.48h37.42L29.43,39.64z"/>
    </StyledYard>
  );
}

export default yard;
