import React from 'react';
import styled from 'styled-components';

const StyledShareItem = styled.button`
  border: unset;
  width: 100%;
  height: 100vh;
  max-width: 70px;
  max-height: 70px;
  background-color: ${props => props.theme.colors.orange};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.2em;
  transition: background 0.3s;
  text-decoration: none;
  text-align: center;


  p {
    margin: 0.3em 0;
    color: ${props => props.theme.colors.white};
    font-size: 0.7em;
  }

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.orangeHover};
  }

  &:active {
    background-color: ${props => props.theme.colors.orangeActive};
  }

  ${props => props.theme.media.largeSmall`
    max-width: 100px;
    max-height: 100px;
    margin: 0 0.5em;

    p {
      font-size: 1em;
    }
  `}
`;
const shareItem = ( props ) => {
  return(
    <StyledShareItem
      onClick={props.clicked}
      href={props.link}
      as={props.as}
      {...props}>
      {props.children}
      {props.type !== 'Download' ? <p>Share on {props.type}</p> : <p>{props.type}</p>}

    </StyledShareItem>
  );
}

export default shareItem;
