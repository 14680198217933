import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BasicDoor from './HouseParts/Doors/BasicDoor';
import CandyDoor from './HouseParts/Doors/CandyDoor';
import DogDoor from './HouseParts/Doors/DogDoor';
import NoreasterDoor from './HouseParts/Doors/NoreasterDoor';
import TropicalDoor from './HouseParts/Doors/TropicalDoor';
import TraditionalDoor from './HouseParts/Doors/TraditionalDoor';
import WorkshopDoor from './HouseParts/Doors/WorkshopDoor';
import CrayolaDoor from './HouseParts/Doors/CrayolaDoor';
import HanukkahDoor from './HouseParts/Doors/HanukkahDoor';
import CatDoor from './HouseParts/Doors/CatDoor';
import PostureDoor from './HouseParts/Doors/PostureDoor';
import DougDoor from './HouseParts/Doors/DougDoor';

class HousePartDoor extends Component {
  render() {
    let part = null;

    switch(this.props.type) {
      case ('basic-door'):
        part = <BasicDoor />;
        break;
      case ('candy-door'):
        part = <CandyDoor />;
        break;
      case ('dog-door'):
        part = <DogDoor />;
        break;
      case ('noreaster-door'):
        part = <NoreasterDoor />;
        break;
      case ('tropical-door'):
        part = <TropicalDoor />;
        break;
      case ('traditional-door'):
        part = <TraditionalDoor />;
        break;
      case ('workshop-door'):
        part = <WorkshopDoor />;
        break;
      case ('crayola-door'):
        part = <CrayolaDoor />;
        break;
      case ('hanukkah-door'):
        part = <HanukkahDoor />;
        break;
      case ('posture-door'):
        part = <PostureDoor />;
        break;
      case ('cat-door'):
        part = <CatDoor />;
        break;
      case ('doug-door'):
        part = <DougDoor />;
        break;
      default:
        part = null;
    }
    return part;
  }
}

HousePartDoor.propTypes = {
  type: PropTypes.string.isRequired,
}
export default HousePartDoor;
