/*
 * DownloadArrow Functional Component
 * This component displays the arrrow for the download button.
 */

import React from 'react';
import styled from 'styled-components';

const StyledDownloadArrow = styled.svg`
  fill: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  max-width: 25px;
  max-height: 25px;

  ${props => props.theme.media.largeSmall`
    max-width: 40px;
    max-height: 40px;
  `}
`;
const downloadArrow = ( props ) => {
  return(
    <StyledDownloadArrow viewBox="0 0 66.56 76.17">
      <path d="M66.44,42.23c-0.25-0.59-0.82-0.98-1.47-0.98H47.6V1.59C47.6,0.71,46.88,0,46.01,0c0,0,0,0,0,0H20.62
      	c-0.88,0-1.59,0.71-1.59,1.59v39.66H1.59C0.71,41.25,0,41.96,0,42.83c0,0.42,0.17,0.82,0.46,1.12l31.65,31.75
      c0.62,0.62,1.63,0.62,2.25,0L66.1,43.96C66.55,43.5,66.69,42.82,66.44,42.23z"/>
    </StyledDownloadArrow>
  );
}

export default downloadArrow;
