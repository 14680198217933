import React from 'react';
import styled from 'styled-components';

import workshopDoorImage from '../../../../../assets/images/workshop-house/workshop-door.png';


const StyledWorkshopDoor = styled.image`
  overflow: visible;
`;

const workshopDoor = ( props ) => {
  return(
    <StyledWorkshopDoor
      id="workshopDoor"
      width="182"
      height="392"
      xlinkHref={workshopDoorImage}
      transform="matrix(1 0 0 1 126 78)"
    />
  );
}

export default workshopDoor;
