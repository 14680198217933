import React from 'react';
import styled from 'styled-components';

import postureRoofImage from '../../../../../assets/images/posture-house/posture-roof.png';

const StyledPostureRoof = styled.image`
  overflow: visible;
`;

const postureRoof = ( props ) => {
  return(
    <StyledPostureRoof
      id="postureRoof"
      width="468"
      height="254"
      xlinkHref={postureRoofImage}
      transform="matrix(1 0 0 1 55 0)"
    />
  );
}

export default postureRoof;
