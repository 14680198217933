/*
 * Share Socials Menu Constants Object
 */

import facebookIcon from '../../components/Logo/FacebookLogo';
import instagramIcon from '../../components/Logo/InstagramLogo';
import twitterIcon from '../../components/Logo/TwitterLogo';
import DownloadIcon from '../../components/Arrow/DownloadArrow';

const SHARE_SOCIALS = [
  {
    id: 1,
    icon: facebookIcon,
    type: 'Facebook',
  },
  {
    id: 2,
    icon: twitterIcon,
    type: 'Twitter',
  },
  {
    id: 3,
    icon: instagramIcon,
    type: 'Instagram',
  },
  {
    id: 4,
    icon: DownloadIcon,
    type: 'Download',
  },
];


export default SHARE_SOCIALS;
