import React from 'react';
import styled from 'styled-components';

import candyYardImage from '../../../../../assets/images/candyland-house/candyland-yard.png';

const StyledCandyYard = styled.image`
    overflow: visible;
`;

const candyYard = ( props ) => {
  return(
    <StyledCandyYard
      id="candyYard"
      width="600"
      height="600"
      xlinkHref={candyYardImage}
    />
  );
}

export default candyYard;
