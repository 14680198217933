import React from 'react';
import styled from 'styled-components';

import postureYardImage from '../../../../../assets/images/posture-house/posture-yard.png';

const StyledPostureYard = styled.image`
    overflow: visible;
`;

const postureYard = ( props ) => {
  return(
    <StyledPostureYard
      id="postureYard"
      width="599"
      height="276"
      xlinkHref={postureYardImage}
      transform="matrix(1 0 0 1 0 323)"
    />
  );
}

export default postureYard;
