import React, { Component } from 'react';
import PropTypes from 'prop-types';

import JanetWeisFoundation from './HouseParts/Foundations/JanetWeisFoundation';
import UnitedWayFoundation from './HouseParts/Foundations/UnitedWayFoundation';
import PoconoWatersFoundation from './HouseParts/Foundations/PoconoWatersFoundation';
import AieFoundation from './HouseParts/Foundations/AieFoundation';
import BluechipFoundation from './HouseParts/Foundations/BluechipFoundation';

class HousePartFoundation extends Component {
  render() {
    let part = null;

    switch(this.props.type) {
      case ('janetweis-foundation'):
        part = <JanetWeisFoundation />;
        break;
      case ('unitedway-foundation'):
        part = <UnitedWayFoundation />;
        break;
      case ('poconowaters-foundation'):
        part = <PoconoWatersFoundation />;
        break;
      case ('aie-foundation'):
        part = <AieFoundation />;
        break;
      case ('bluechip-foundation'):
        part = <BluechipFoundation />;
        break;
      default:
        part = null;
    }
    return part;
  }
}

HousePartFoundation.propTypes = {
  type: PropTypes.string.isRequired,
}
export default HousePartFoundation;
