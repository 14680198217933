import React from 'react';
import styled from 'styled-components';

import hanukkahYardImage from '../../../../../assets/images/hanukkah-house/hanukkah-yard.png';

const StyledHanukkahYard = styled.image`
    overflow: visible;
`;

const hanukkahYard = ( props ) => {
  return(
    <StyledHanukkahYard
      id="hanukkahYard"
      width="600"
      height="275"
      xlinkHref={hanukkahYardImage}
      transform="matrix(1 0 0 1 0 324)"
    />
  );
}

export default hanukkahYard;
