import React from 'react';
import styled from 'styled-components';

const StyledPostureHousePartyLogoHorizontal = styled.svg`
  width: 100%;
  height: 40px;

  ${props => props.theme.media.large`
    height: auto;
  `}

  .st0 {
    fill: ${props => props.theme.colors.orange};
  }

  .st1 {
    fill: ${props => props.theme.colors.white};
  }
`;

const postureHousePartyLogoHorizontal = ( props ) => {
  return(
    <StyledPostureHousePartyLogoHorizontal viewBox="0 0 532.3 53.7">
      <path className="st0" d="M1.5 2.2h20.3v15.2H32V2.2h20.3v49.2H32v-18H21.8v18H1.5V2.2z"/>
      <path className="st1" d="M53.7 53H30.5V35h-7.2v18H0V.7h23.3V16h7.2V.7h23.3V53zm-20.2-3h17.3V3.7H33.5V19H20.3V3.7H3V50h17.3V32h13.2v18z"/>
      <path className="st0" d="M84.6 1.5c15.4 0 27.5 11.1 27.5 25.4 0 14.3-12 25.4-27.5 25.4S57.1 41.1 57.1 26.9c0-14.3 12.1-25.4 27.5-25.4zm0 33.3c4.2 0 7.6-3.4 7.6-7.6s-3.4-7.6-7.6-7.6S77 23 77 27.2s3.4 7.6 7.6 7.6z"/>
      <path className="st1" d="M84.6 53.7c-16.2 0-29-11.8-29-26.9S68.3 0 84.6 0s29 11.8 29 26.9-12.8 26.8-29 26.8zm0-50.7c-14.6 0-26 10.5-26 23.9s11.4 23.9 26 23.9 26-10.5 26-23.9S99.2 3 84.6 3zm0 33.3c-5 0-9.1-4.1-9.1-9.1s4.1-9.1 9.1-9.1 9.1 4.1 9.1 9.1-4.1 9.1-9.1 9.1zm0-15.2c-3.4 0-6.1 2.7-6.1 6.1s2.7 6.1 6.1 6.1 6.1-2.7 6.1-6.1-2.7-6.1-6.1-6.1z"/>
      <path className="st0" d="M116.9 29.1V2.2h20.3v25.5c0 4.6 1.5 7.9 6.2 7.9 4.5 0 6.1-2.9 6.1-7.8V2.2h18.2v26.6c0 7.5-1.9 12.3-4.5 15.4-4.5 5.4-12.6 8-21 8-8.6 0-16.3-2.7-20.6-7.8-2.5-2.9-4.7-7.3-4.7-15.3z"/>
      <path className="st1" d="M142.1 53.7c-9.3 0-17.2-3-21.7-8.3-3.4-3.9-5-9.2-5-16.3V.7h23.3v27c0 5.6 2.4 6.4 4.7 6.4 2.7 0 4.6-1.1 4.6-6.3V.7h21.2v28.1c0 7-1.6 12.5-4.8 16.4-4.6 5.4-12.6 8.5-22.3 8.5zm-23.7-50v25.4c0 6.4 1.4 10.9 4.3 14.3 4 4.6 11.1 7.3 19.5 7.3 8.7 0 16-2.7 19.9-7.4 2.8-3.3 4.1-8.2 4.1-14.5V3.7H151v24.1c0 6.2-2.5 9.3-7.6 9.3-3.5 0-7.7-1.6-7.7-9.4v-24h-17.3z"/>
      <path className="st0" d="M173.7 31.5c6.6 4.3 13.3 5.8 16.2 5.8 2 0 2.5-.8 2.5-1.6 0-1-.6-1.4-1.5-1.6-2.4-.6-9.2-1.4-14.3-5.8-2.6-2.3-4.4-5.5-4.4-10.3 0-9.9 7.6-16.6 20.1-16.6 6 0 13.1 1.6 17.5 3.9v16.1c-6.4-3.2-12.4-4.4-14.9-4.4-2 0-2.5.7-2.5 1.5 0 1 .7 1.4 1.6 1.7 2 .6 8.8 1.4 13.7 5.2 3.3 2.7 5 6.2 5 10.6 0 9.5-7.6 16-20.5 16-6.2 0-13.6-1.5-18.4-4V31.5z"/>
      <path className="st1" d="M192.1 53.7c-6.7 0-14.2-1.6-19.1-4.2l-.8-.4V28.7l2.3 1.5c6.3 4.1 12.7 5.6 15.4 5.6.6 0 .9-.1 1-.1-.1 0-.2-.1-.3-.1-.4-.1-.9-.2-1.4-.3-3.1-.6-9-1.8-13.5-5.8-3.3-2.9-4.9-6.7-4.9-11.4 0-11 8.5-18.1 21.6-18.1 6.2 0 13.5 1.6 18.2 4l.8.4v19.5l-2.2-1.1c-6.2-3.1-11.9-4.2-14.2-4.2-.5 0-.7 0-.9.1.1 0 .2.1.4.2.3.1.9.2 1.5.4 2.9.6 8.4 1.8 12.6 5.1 3.7 3 5.5 6.9 5.5 11.8 0 10.6-8.6 17.5-22 17.5zm-16.9-6.4c4.4 2.1 11.1 3.4 16.9 3.4 11.7 0 19-5.6 19-14.5 0-4-1.4-7-4.4-9.4-3.6-2.8-8.4-3.9-11.3-4.5-.8-.2-1.4-.3-1.8-.4-1.8-.6-2.6-1.6-2.6-3.1 0-.5.2-3 4-3 2.8 0 8 1.1 13.4 3.5v-13c-4.3-2-10.6-3.3-16-3.3-11.3 0-18.6 5.9-18.6 15.1 0 3.8 1.3 6.8 3.9 9.1 3.9 3.4 9 4.5 12.1 5.1.6.1 1.1.2 1.6.3 2.3.5 2.6 2.1 2.6 3 0 .7-.3 3.1-4 3.1-3.2 0-9-1.5-14.7-4.7v13.3zm18.7-28.7z"/>
      <path className="st0" d="M216.9 2.2h35.4v16.5h-15.2v2.7h12.2V33h-12.2v2.8h16.1v15.6h-36.4V2.2z"/>
      <path className="st1" d="M254.8 53h-39.4V.7h38.4v19.5h-2.9v14.1h3.9V53zm-36.4-3h33.4V37.3h-16.1v-5.8h12.2v-8.7h-12.2v-5.7h15.2V3.7h-32.4V50z"/>
      <g>
        <path className="st0" d="M278.7 2.2h18.6c11.8 0 25.1 3.4 25.1 17.5 0 12.6-10.6 17.7-22.8 17.7h-.6v14h-20.3V2.2zM299.5 25c3.5 0 5.1-1.8 5.1-4.1s-1.7-4.1-5.1-4.1h-.5V25h.5z"/>
        <path className="st1" d="M300.5 53h-23.3V.7h20.1c17.1 0 26.6 6.7 26.6 19 0 11.8-8.8 19-23.5 19.2V53zm-20.3-3h17.3V36h2.2c19.2 0 21.3-11.4 21.3-16.2 0-13.2-12.8-16-23.6-16h-17.1V50zm19.3-23.5h-2V15.2h2c4.6 0 6.6 2.8 6.6 5.6 0 2.6-1.7 5.7-6.6 5.7zm1-8.3v5.2c1.2-.2 2.6-.8 2.6-2.6 0-1.4-.9-2.3-2.6-2.6z"/>
      </g>
      <g>
        <path className="st0" d="M336.2 2.2h19.4l18.8 49.2h-21.9l-1.7-6.5h-11.3l-1.9 6.5h-20.1l18.7-49.2zm11.3 30.4l-2.2-10h-.1l-2.2 10h4.5z"/>
        <path className="st1" d="M376.5 53h-25.2l-1.7-6.5h-9l-1.9 6.5h-23.4L335.2.8h21.4L376.5 53zm-22.9-3h18.6L354.5 3.7h-17.3L319.6 50h16.8l1.9-6.5h13.6l1.7 6.5zm-4.3-15.9h-8.2l2.8-13h2.6l2.8 13zm-4.4-3h.8l-.4-1.8-.4 1.8z"/>
      </g>
      <g>
        <path className="st0" d="M377.7 2.2h20.2c10.1 0 23.9 2.5 23.9 15.6 0 5.3-2.5 9.1-6.2 11.7l11.2 21.9h-21.3l-7.3-16.2h-.2v16.2h-20.3V2.2zM399.5 24c3.2 0 4.6-1.7 4.6-3.8 0-2.1-1.5-3.7-4.6-3.7H398V24h1.5z"/>
        <path className="st1" d="M429.4 53h-24.7l-5.1-11.3V53h-23.3V.7H398c16.4 0 25.4 6.1 25.4 17.1 0 4.9-2 9-5.8 12.1L429.4 53zm-22.8-3h17.9l-10.7-20.9 1.1-.8c3.7-2.6 5.6-6.1 5.6-10.5 0-6.4-3.9-14.1-22.4-14.1h-18.7V50h17.3V33.8h2.7l7.2 16.2zm-7.1-24.5h-3V15h3c4.5 0 6.1 2.8 6.1 5.2 0 2.4-1.6 5.3-6.1 5.3zm0-7.5v4.5c1.4 0 3.1-.4 3.1-2.3s-1.7-2.2-3.1-2.2z"/>
      </g>
      <g>
        <path className="st0" d="M438.6 19h-13.7V2.2h47.7V19h-13.7v32.5h-20.3V19z"/>
        <path className="st1" d="M460.4 53h-23.3V20.5h-13.7V.7h50.7v19.7h-13.7V53zm-20.3-3h17.3V17.5h13.7V3.7h-44.7v13.7h13.7V50z"/>
      </g>
      <g>
        <path className="st0" d="M491.3 30.7L473.2 2.2H496l5.6 11.2h.1l5.6-11.2h22.1l-18 27.8v21.4h-20.3V30.7z"/>
        <path className="st1" d="M513.1 53h-23.3V31.1L470.5.7H497l4.7 9.5 4.8-9.5h25.8l-19.2 29.8V53zm-20.3-3h17.3V29.6l16.7-25.9h-18.4L502.7 15h-2l-5.6-11.2h-19.2l16.9 26.5V50z"/>
      </g>
    </StyledPostureHousePartyLogoHorizontal>
  );
}

export default postureHousePartyLogoHorizontal;
