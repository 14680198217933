import React from 'react';
import styled from 'styled-components';

import postureLocationImage from '../../../../../assets/images/posture-house/posture-background.png';

const StyledPostureLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const postureLocation = ( props ) => {
  return(
    <StyledPostureLocation
      id="postureLocation"
      width="600"
      height="600"
      xlinkHref={postureLocationImage}
    />
  );
}

export default postureLocation;
