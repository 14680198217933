/*
 * CandyCane Functional Component
 * This component displays the candy cane image.
 */

import React from 'react';
import styled from 'styled-components';

import CandyCaneImage from '../../assets/images/candycane@2x.png';

const StyledCandyCane = styled.img`
  max-width: 20%;
  height: auto;
  width: 100%;
  object-fit: contain;
  max-height: 400px;

  ${props => props.theme.media.medium`
    max-height: 400px;
    height: auto;
  `}

  ${props => props.theme.media.large`
    width: 100%;
    margin-top: 2em;
  `}
`;

const candyCane = ( props ) => {
  return(
    <StyledCandyCane src={CandyCaneImage}/>
  );
};

export default candyCane;
