import React from 'react';
import styled from 'styled-components';

import dougRoofImage from '../../../../../assets/images/doug-house/doug-roof-thumbnail.png';

const StyledDougRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dougRoofThumbnail = ( props ) => {
  return(
    <StyledDougRoofThumbnail
      id="dougRoofThumbnail"
      src={dougRoofImage}
    />
  );
}

export default dougRoofThumbnail;
