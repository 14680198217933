import React from 'react';
import styled from 'styled-components';

import noreasterYardImage from '../../../../../assets/images/noreaster-house/noreaster-yard-thumbnail.png';


const StyledNoreasterYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const noreasterYardThumbnail = ( props ) => {
  return(
      <StyledNoreasterYardThumbnail
        id="noreasterYardThumbnail"
        src={noreasterYardImage}
      />
  );
}

export default noreasterYardThumbnail;
