import React from 'react';
import styled from 'styled-components';

import posterImage from '../../assets/images/video_poster.png';

const StyledVideo = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const video = ( props ) => {
  return(
    <StyledVideo
      controls
      playsInline
      controlsList="nodownload nofullscreen"
      poster={posterImage}
    >
      <source src={props.videoSrc} type="video/mp4"/>
    </StyledVideo>
  );
}

export default video;
