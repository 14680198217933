import React from 'react';
import styled from 'styled-components';

import hanukkahBackground from '../../../../../assets/images/hanukkah-house/hanukkah-background-thumbnail.png';

const StyledHanukkahLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const hanukkahLocationThumbnail = ( props ) => {
  return(
      <StyledHanukkahLocationThumbnail
        id="hanukkahLocationThumbnail"
        src={hanukkahBackground}
      />
  );
}

export default hanukkahLocationThumbnail;
