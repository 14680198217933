import React from 'react';
import styled from 'styled-components';

const StyledRoof = styled.svg`
  max-width: 41px;
  width: 100%;
  fill: ${props => props.theme.colors.darkBlue};
  transition: fill 0.3s;
`;


const roof = ( props ) => {
  return(
    <StyledRoof viewBox="0 0 50.1 24">
      <path d="M18.4 0L0 23.8l19.7.1L38.1.1zM38.6 1.6l-2.7 3.9L45.4 24h4.7z"/>
    </StyledRoof>
  );
}

export default roof;
