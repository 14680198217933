import React from 'react';
import styled from 'styled-components';

import tropicalYardImage from '../../../../../assets/images/tropical-house/tropical-yard.png';

const StyledTropicalYard = styled.image`
    overflow: visible;
`;

const tropicalYard = ( props ) => {
  return(
    <StyledTropicalYard
      id="tropicalYard"
      width="600"
      height="361"
      xlinkHref={tropicalYardImage}
      transform="matrix(1 0 0 1 0 240)"
    />
  );
}

export default tropicalYard;
