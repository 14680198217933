import React from 'react';
import styled from 'styled-components';

import basicBackground from '../../../../../assets/images/basic-house/basic-background-thumbnail.png';

const StyledBasicLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const basicLocationThumbnail = ( props ) => {
  return(
      <StyledBasicLocationThumbnail
        id="basicLocationThumbnail"
        src={basicBackground}
      />
  );
}

export default basicLocationThumbnail;
