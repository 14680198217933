import React from 'react';
import styled from 'styled-components';

import unitedWayFoundationImage from '../../../../../assets/images/foundation/unitedway-foundation.png';


const StyledUnitedWayFoundation = styled.image`
  overflow: visible;
`;

const unitedWayFoundation = ( props ) => {
  return(
    <StyledUnitedWayFoundation
      id="unitedWayLogo"
      width="190"
      height="95"
      xlinkHref={unitedWayFoundationImage}
      transform="matrix(1 0 0 1 374 471)"
    />
  );
}

export default unitedWayFoundation;
