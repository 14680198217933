import React from 'react';
import styled from 'styled-components';

import crayolaYardImage from '../../../../../assets/images/crayola-house/crayola-yard.png';

const StyledCrayolaYard = styled.image`
    overflow: visible;
`;

const crayolaYard = ( props ) => {
  return(
    <StyledCrayolaYard
      id="crayolaYard"
      width="601"
      height="255"
      xlinkHref={crayolaYardImage}
      transform="matrix(1 0 0 1 -3 346)"
    />
  );
}

export default crayolaYard;
