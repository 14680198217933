import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BasicWindow from './HouseParts/Windows/BasicWindow';
import CandyWindow from './HouseParts/Windows/CandyWindow';
import DogWindow from './HouseParts/Windows/DogWindow';
import NoreasterWindow from './HouseParts/Windows/NoreasterWindow';
import TropicalWindow from './HouseParts/Windows/TropicalWindow';
import TraditionalWindow from './HouseParts/Windows/TraditionalWindow';
import WorkshopWindow from './HouseParts/Windows/WorkshopWindow';
import CrayolaWindow from './HouseParts/Windows/CrayolaWindow';
import CatWindow from './HouseParts/Windows/CatWindow';
import PostureWindow from './HouseParts/Windows/PostureWindow';
import HanukkahWindow from './HouseParts/Windows/HanukkahWindow';
import DougWindow from './HouseParts/Windows/DougWindow';

class HousePartWindow extends Component {
  render() {
    let part = null;

    switch(this.props.type) {
      case ('basic-window'):
        part = <BasicWindow />;
        break;
      case ('candy-window'):
        part = <CandyWindow />;
        break;
      case ('dog-window'):
        part = <DogWindow />;
        break;
      case ('noreaster-window'):
        part = <NoreasterWindow />;
        break;
      case ('tropical-window'):
        part = <TropicalWindow />;
        break;
      case ('traditional-window'):
        part = <TraditionalWindow />;
        break;
      case ('workshop-window'):
        part = <WorkshopWindow />;
        break;
      case ('crayola-window'):
        part = <CrayolaWindow />;
        break;
      case ('cat-window'):
        part = <CatWindow />;
        break;
      case ('posture-window'):
        part = <PostureWindow />;
        break;
      case ('hanukkah-window'):
        part = <HanukkahWindow />;
        break;
      case ('doug-window'):
        part = <DougWindow />;
        break;
      default:
        part = null;
    }
    return part;
  }
}

HousePartWindow.propTypes = {
  type: PropTypes.string.isRequired,
}

export default HousePartWindow;
