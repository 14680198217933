import React from 'react';
import styled from 'styled-components';


const StyledLocation = styled.svg`
  max-width: 27px;
  width: 100%;
  fill: ${props => props.theme.colors.darkBlue};
  transition: fill 0.3s;
  -webkit-backface-visibility: hidden;
`;

const location = ( props ) => {
  return(
    <StyledLocation viewBox="0 0 33.43 48.527">
      <path d="M32.621 12.064a16.954 16.954 0 0 0-.809-1.955A16.223 16.223 0 0 0 16.513 0 16.347 16.347 0 0 0 0 15.03v2.089A10.626 10.626 0 0 0 .067 18.4c.607 4.785 4.448 9.908 7.279 14.76 3.033 5.19 6.268 10.245 9.368 15.367 1.955-3.3 3.909-6.673 5.8-9.908.539-.944 1.146-1.887 1.618-2.831.337-.607 1.011-1.213 1.281-1.752 3.033-5.594 8.02-11.256 8.02-16.85V14.9a16.313 16.313 0 0 0-.809-2.831zm-15.973 10.38a5.9 5.9 0 0 1-5.662-4.044 4.918 4.918 0 0 1-.2-1.483v-1.348c0-3.774 3.235-5.527 6-5.527a6.127 6.127 0 0 1 6.133 6.2 6.242 6.242 0 0 1-6.268 6.2zm0 0"/>
    </StyledLocation>
  );
}

export default location;
