import React from 'react';
import styled from 'styled-components';

import dogDoorImage from '../../../../../assets/images/dog-house/doghouse-door.png';


const StyledDogDoor = styled.image`
  overflow: visible;
`;

const dogDoor = ( props ) => {
  return(
    <StyledDogDoor
      id="dogDoor"
      width="181"
      height="391"
      xlinkHref={dogDoorImage}
      transform="matrix(1 0 0 1 128 79)"
    />
  );
}

export default dogDoor;
