import React from 'react';
import styled from 'styled-components';

import dougDoorImage from '../../../../../assets/images/doug-house/doug-door-thumbnail.png';

const StyledDougDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dougDoorThumbnail = ( props ) => {
  return(
    <StyledDougDoorThumbnail
      id="dougDoorThumbnail"
      src={dougDoorImage}
    />
  );
}

export default dougDoorThumbnail;
