import React from 'react';
import styled from 'styled-components';

import basicRoofImage from '../../../../../assets/images/basic-house/basic-roof-thumbnail.png';

const StyledBasicRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const basicRoofThumbnail = ( props ) => {
  return(
    <StyledBasicRoofThumbnail
      id="basicRoofThumbnail"
      src={basicRoofImage}
    />
  );
}

export default basicRoofThumbnail;
