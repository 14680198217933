import React from 'react';
import styled from 'styled-components';

const StyledFacebookLogo = styled.svg`
  fill: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  max-width: 15px;
  max-height: 35px;

  ${props => props.theme.media.largeSmall`
    max-width: 19px;
  `}
`;

const facebookLogo = ( props ) => {
  return(
    <StyledFacebookLogo viewBox="0 0 27.647 51.2">
      <path d="M26.583,0H19.938C12.494,0,7.656,4.945,7.656,12.6v5.8H1.063A1.066,1.066,0,0,0,0,19.459v8.4a1.066,1.066,0,0,0,1.063,1.063H7.709V50.136A1.066,1.066,0,0,0,8.773,51.2h8.719a1.066,1.066,0,0,0,1.063-1.063V28.87h7.816a1.066,1.066,0,0,0,1.063-1.063v-8.4a1.09,1.09,0,0,0-1.063-1.063H18.5V13.451c0-2.339.585-3.562,3.615-3.562h4.466a1.066,1.066,0,0,0,1.063-1.063V1.063A1.066,1.066,0,0,0,26.583,0Z"/>
    </StyledFacebookLogo>
  );
}

export default facebookLogo;
