import React from 'react';
import styled from 'styled-components';

import postureDoorImage from '../../../../../assets/images/posture-house/posture-door-thumbnail.png';

const StyledPostureDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const postureDoorThumbnail = ( props ) => {
  return(
    <StyledPostureDoorThumbnail
      id="postureDoorThumbnail"
      src={postureDoorImage}
    />
  );
}

export default postureDoorThumbnail;
