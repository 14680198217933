import React from 'react';
import styled from 'styled-components';

import traditionalWindowImage from '../../../../../assets/images/traditional-house/traditional-window.png';

const StyledTraditionalWindow = styled.image`
  overflow: visible;
`;

const traditionalWindow = ( props ) => {
  return(
    <StyledTraditionalWindow
      id="traditionalWindow"
      width="215"
      height="235"
      xlinkHref={traditionalWindowImage}
      transform="matrix(1 0 0 1 301 241)"
    />
  );
}

export default traditionalWindow;
