import React from 'react';
import styled from 'styled-components';

import workshopYardImage from '../../../../../assets/images/workshop-house/workshop-yard.png';

const StyledWorkshopYard = styled.image`
    overflow: visible;
`;

const workshopYard = ( props ) => {
  return(
    <StyledWorkshopYard
      id="workshopYard"
      width="591"
      height="374"
      xlinkHref={workshopYardImage}
      transform="matrix(1 0 0 1 0 226)"
    />
  );
}

export default workshopYard;
