import React from 'react';
import styled from 'styled-components';

import noreasterDoorImage from '../../../../../assets/images/noreaster-house/noreaster-door-thumbnail.png';

const StyledNoreasterDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const noreasterDoorThumbnail = ( props ) => {
  return(
    <StyledNoreasterDoorThumbnail
      id="noreasterDoorThumbnail"
      src={noreasterDoorImage}
    />
  );
}

export default noreasterDoorThumbnail;
