import React from 'react';
import styled from 'styled-components';

const StyledHousePartyLogo = styled.svg`
  width: 80%;


  ${props => props.theme.media.large`
    width: 100%;
  `}

  .st0 {
    fill: ${props => props.theme.colors.orange};
  }

  .st1 {
    fill: ${props => props.theme.colors.white};
  }
`;
const HousePartyLogo = ( props ) => {
  return(
    <StyledHousePartyLogo viewBox="0 0 397 175.5">
      <g>
      	<path id="Path_64" className="st0" d="M336.3,2.6h55.1v25.6h-23.6v4.1h19v18.2h-19v4.3h25.1v24.3h-56.5V2.6z"/>
      	<path className="st1" d="M394.3,56.2v-2.7H391V33.6h-2.7v-1.3h7.3V3.9h-2.7V1.2h-57.9v79.4h2.7v2.7H397V56.2H394.3z M392.9,79.2h-56.5
       V2.6h55.1v25.6h-23.6v4.1h19v18.2h-19v4.3h25.1V79.2z"/>
      </g>
      <g>
      	<path id="Path_62" className="st0" d="M269.1,48.1c10.2,6.7,20.6,9.1,25.2,9.1c3.2,0,3.9-1.3,3.9-2.5c0-1.5-1-2.1-2.3-2.4
      		c-3.7-1-14.4-2.1-22.2-9c-4-3.6-6.8-8.6-6.8-16c0-15.3,11.9-25.8,31.2-25.8c9.3,0,20.3,2.4,27.3,6v25.1c-10-5-19.2-6.8-23.2-6.8
      		c-3,0-3.9,1.1-3.9,2.3c0,1.6,1.1,2.2,2.4,2.6c3.2,1,13.7,2.2,21.2,8.1c5.1,3.9,7.9,10.1,7.7,16.5c0,14.7-11.9,25-31.9,25
       c-9.6,0-21.2-2.3-28.6-6.2L269.1,48.1L269.1,48.1z"/>
      	<path className="st1" d="M326.3,41c-1-1.2-2.1-2.3-3.4-3.3c-6.3-4.9-14.6-6.7-19.1-7.7c0.4-0.1,0.8-0.1,1.1-0.1
      		c3.8,0,12.8,1.8,22.5,6.6l2,1V9.3L328.6,9c-0.6-0.3-1.3-0.6-2-0.9V6.6l-0.8-0.4C318.8,2.5,307.5,0,298,0
      		c-19.8,0-32.6,10.7-32.6,27.2c0,7.1,2.4,12.7,7.2,17.1c0.2,0.1,0.3,0.3,0.5,0.4c0.7,0.8,1.4,1.6,2.2,2.3c6.5,5.7,15,7.6,19.9,8.7
      		c-0.3,0-0.6,0.1-1,0.1c-4.3,0-14.4-2.4-24.4-8.9l-2.2-1.4V75l0.8,0.4c0.6,0.3,1.3,0.6,2,0.9v1.4l0.8,0.4c7.4,3.9,19,6.4,29.3,6.4
      		c20.2,0,33.3-10.3,33.3-26.4C333.8,51.1,331.3,45.5,326.3,41z M297.7,80.3c-9.6,0-21.2-2.3-28.6-6.2v-26c0,0,0,0,0,0h0v0
      		c10.2,6.7,20.6,9.1,25.2,9.1c3.2,0,3.9-1.3,3.9-2.5c0-1.5-1-2.1-2.3-2.4c-3.7-1-14.4-2.1-22.2-9c-4-3.6-6.8-8.6-6.8-16
      		c0-15.3,11.9-25.8,31.2-25.8c9.3,0,20.3,2.4,27.3,6v25.1c-10-5-19.2-6.8-23.2-6.8c-3,0-3.9,1.1-3.9,2.3c0,1.6,1.1,2.2,2.4,2.6
       c3.2,1,13.7,2.2,21.2,8.1c5.1,3.9,7.9,10.1,7.7,16.5C329.6,70,317.8,80.3,297.7,80.3z"/>
      </g>
      <g>
      	<path id="Path_60" className="st0" d="M180.8,44.4V2.6h31.5v39.6c0,7.2,2.3,12.2,9.6,12.2c7,0,9.4-4.4,9.4-12.1V2.6h28.3V44
       c0,11.7-3,19.2-7,24c-7,8.5-19.7,12.4-32.7,12.4c-13.4,0-25.2-4.2-32-12.1C184.2,63.7,180.8,56.9,180.8,44.4z"/>
      	<path className="st1" d="M261.1,3.9V1.2h-31.2v41.2c0,7.6-2.3,10.7-8,10.7c-1.6,0-3-0.3-4.1-0.9c-0.9-1.7-1.4-4.1-1.4-7.2V3.9h-2.7V1.2
      		h-34.3v43.2c0,10.7,2.5,18.8,7.5,24.7c0.9,1.1,1.9,2,3,3c6.9,7.9,18.8,12.4,32.9,12.4c14.7,0,27-4.7,33.8-12.9
      		c4.8-5.9,7.3-14.2,7.3-24.9V3.9H261.1z M259.7,44c0,11.7-3,19.1-7,24c-7,8.5-19.7,12.4-32.7,12.4c-13.4,0-25.2-4.2-32-12.1
       c-3.8-4.5-7.2-11.3-7.2-23.8V2.6h31.5v39.6c0,7.2,2.3,12.2,9.6,12.2c7,0,9.4-4.4,9.4-12.1V2.6h28.3V44z"/>
      </g>
      <g>
      	<path id="Path_58" className="st0" d="M130.6,1.5c24,0,42.7,17.2,42.7,39.4s-18.7,39.4-42.7,39.4S87.9,63.1,87.9,40.9
      		S106.6,1.5,130.6,1.5z M130.6,53.3c6.6,0,11.9-5.3,11.9-11.9s-5.3-11.9-11.9-11.9c-6.6,0-11.9,5.3-11.9,11.9
       C118.7,47.9,124,53.2,130.6,53.3C130.6,53.3,130.6,53.3,130.6,53.3z"/>
      	<path className="st1" d="M164.7,14.6c-8-9-20.2-14.6-34-14.6c-24.8,0-44.2,18-44.2,40.9c0,11.5,4.8,21.7,12.8,29c8,9,20.2,14.6,34,14.6
      		c24.8,0,44.2-18,44.2-40.9C177.4,32.1,172.6,21.9,164.7,14.6z M130.6,80.4c-24,0-42.7-17.2-42.7-39.4s18.7-39.4,42.7-39.4
       c24,0,42.7,17.2,42.7,39.4C173.3,63.1,154.6,80.4,130.6,80.4z"/>
      	<g>
      		<path className="st1" d="M123.6,51c-1.4-1.9-2.2-4.3-2.2-6.8c0-6.6,5.3-11.9,11.9-11.9c2.6,0,5.1,0.9,7,2.3c-2.1-3-5.7-5-9.7-5
        c-6.6,0-11.9,5.3-11.9,11.9C118.7,45.3,120.7,48.8,123.6,51z"/>
      	</g>
      </g>
      <g>
      	<path id="Path_56" className="st0" d="M1.4,2.6h31.5v23.7h15.9V2.6h31.5v76.6H48.8V51.3H32.9v27.9H1.4V2.6z"/>
      	<path className="st1" d="M81.7,3.9V1.2H47.3v23.7H37.1v-21h-2.7V1.2H0v79.4h2.7v2.7h34.4V55.4h10.3v25.2h2.7v2.7h34.3V3.9H81.7z
       M80.3,79.2H48.8V51.3H32.9v27.9H1.4V2.6h31.5v23.7h15.9V2.6h31.5V79.2z"/>
      </g>
      <g>
      	<path id="Path_72" className="st0" d="M251.4,119.7h-21.3v-26h74.2v26H283v50.5h-31.5L251.4,119.7L251.4,119.7z"/>
      	<path className="st1" d="M305.7,94.9v-2.7h-77.1v28.9h2.7v2.7H250v47.8h2.7v2.7h34.3h0v-50.5h21.3V94.9H305.7z M304.3,119.7H283v50.5
       h-31.5v-50.5h0h-21.3v-26h74.2V119.7z"/>
      </g>
      <g>
      	<path id="Path_74" className="st0" d="M299.2,136.1c7.3,4.5,15.4,7.9,24.7,7.9c2.7,0.1,5.4-0.4,7.8-1.5l-23.5-48.9H341l9.8,26.2h0.2
       l7.6-26.2h30.1l-18,47.4c-9.2,24.3-27.7,30.2-44.4,30.2c-10,0-20.6-2.1-27.2-6L299.2,136.1z"/>
      	<path className="st1" d="M389.7,95l1-2.8h-33.2l-6.1,21L344.6,95H343l-1-2.8h-36l23.9,49.6c-1.9,0.6-3.9,0.8-5.9,0.8
      		c-7.9,0-15.5-2.5-24-7.7l-2.2-1.4v32.6l0.7,0.4c0.6,0.4,1.3,0.7,2,1.1v1.2l0.7,0.4c6.4,3.9,17.1,6.2,27.9,6.2
      		c22.5,0,37.9-10.5,45.6-31.1L393.4,95H389.7z M370.8,141.1c-9.2,24.3-27.7,30.2-44.4,30.2c-10,0-20.6-2.1-27.2-6l0-29.2
       c7.3,4.5,15.4,7.9,24.7,7.9c2.7,0.1,5.4-0.4,7.8-1.5l-23.5-48.9H341l9.8,26.2h0.2l7.6-26.2h30.1L370.8,141.1z"/>
      </g>
      <g>
      	<path className="st1" d="M42.1,118.7c-1.4-2.5-4.4-4-8.4-4h-2.3v15.8h2.3c0.1,0,0.3,0,0.4,0v2.7h2.3v0c5.7,0,9.3-3.1,9.3-7.9
      		C45.8,122.3,44.5,120,42.1,118.7z M40.3,122.5c0,2.2-1.1,3.8-3.2,4.5v-6.8c1.1,0,2.1,0.3,2.9,0.6C40.1,121.3,40.3,121.9,40.3,122.5
       z"/>
      	<path id="Path_66" className="st0" d="M1.4,93.7h29c18.4,0,39,5.3,39,27.2c0,19.7-16.5,27.6-35.4,27.6h-1v21.8H1.4V93.7z M33.8,129
       c5.4,0,7.9-2.9,7.9-6.4s-2.6-6.4-7.9-6.4h-0.9V129L33.8,129L33.8,129z"/>
      	<path className="st1" d="M65.1,103.9c-6.5-7.6-18.3-11.6-34.7-11.6H0v79.4h2.7v2.7h34.3v-21.8c10.2,0,19-2.3,25.2-6.7
      		c7.3-5,11.2-12.8,11.2-22.3C73.5,115.2,70.6,108.6,65.1,103.9z M34,148.4h-1v21.8H1.4V93.7h29c18.4,0,39,5.3,39,27.2
       C69.4,140.5,52.9,148.4,34,148.4z"/>
      </g>
      <g>
      	<polygon className="st1" points="103.6,124 99.6,142.3 110.1,142.3 106.1,124 	"/>
      	<path className="st1" d="M124.6,94.9h-1.6l-1-2.7H89.8l-30.2,79.4h3.7l-1,2.7h34.4l3-10.1H113l1.9,7.3h2l0.7,2.7h37.2L124.6,94.9z
       M116,170.2l-2.6-10.1H95.8l-3,10.1H61.6l29.2-76.6h30.1l29.2,76.6H116z"/>
      	<path className="st0" d="M120.9,93.7H90.7l-29.2,76.6h31.3l3-10.1h17.5l2.6,10.1h34.1L120.9,93.7z M99.6,142.3l4-18.4h2.5l4,18.4H99.6z
       "/>
      </g>
      <g>
      	<path id="Path_70" className="st0" d="M155.3,93.7h30.9c15.9,0,37.2,4.1,37.2,24c0,8.5-3.4,14.5-8.9,18.5c1,4.1,4.1,7.3,10.6,7.3
      		c5.8-0.1,11.3-2,16-5.5v27.9c-4.7,3-12.4,5.5-20.3,5.5c-18.1,0-30.7-11.3-33.7-26.5h-0.2v25.4h-31.5L155.3,93.7L155.3,93.7z
       M189.2,127.5c5,0,7.2-2.6,7.2-5.9s-2.3-5.8-7.2-5.8h-2.3v11.7H189.2z"/>
      	<path className="st1" d="M242.9,139.5c-0.1,0.1-0.3,0.2-0.4,0.3v-4.7l-2.3,1.7c-4.4,3.3-9.6,5.1-15.1,5.2c-2.3,0-4.2-0.5-5.7-1.3
      		c-0.2-0.4-0.4-0.8-0.6-1.3c5.8-4.6,8.7-11,8.7-19.1c0-7-2.5-12.6-7.4-16.8c-2.2-2.6-5.1-4.8-8.7-6.6c-8.1-3.9-18.1-4.7-25.2-4.7
      		H154v79.4h2.7v2.7H191v-16.4c1.5,2.2,3.2,4.1,5.1,5.8c6.4,7.4,16.1,11.7,27.4,11.7c7.7,0,15.7-2.2,21.2-5.7l0.6-0.4v-31.5
      		L242.9,139.5z M241.1,165.9c-4.7,3-12.4,5.5-20.3,5.5c-18.1,0-30.7-11.3-33.7-26.5h-0.2v25.4h-31.5V93.7h30.8
       c15.9,0,37.2,4.1,37.2,24c0,8.5-3.4,14.5-8.9,18.5c1,4.1,4.1,7.3,10.6,7.3c5.8-0.1,11.3-2,16-5.5V165.9z"/>
      	<path className="st1" d="M196.4,121.6c0-3.3-2.3-5.8-7.2-5.8h-2.3v11.7h2.3C194.1,127.5,196.4,124.9,196.4,121.6z M191,125.9v-6h0.9
       c1.1,0,2.1,0.2,2.9,0.5c0.1,0.4,0.2,0.8,0.2,1.2C194.9,124,193.5,125.4,191,125.9z"/>
      </g>
    </StyledHousePartyLogo>
  );
}

export default HousePartyLogo;
