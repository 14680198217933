import React from 'react';
import styled from 'styled-components';

const StyledInstagramLogo = styled.svg`
  fill: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  max-width: 25px;
  max-height: 25px;

  ${props => props.theme.media.largeSmall`
    max-width: 35px;
    max-height: 35px;
  `}
`;

const instagramLogo = ( props ) => {
  return(
    <StyledInstagramLogo viewBox="0 0 51.2 51.2">
      <path d="M37.038,0H14.131A14.128,14.128,0,0,0,0,14.131V37.068A14.148,14.148,0,0,0,14.131,51.2H37.068A14.148,14.148,0,0,0,51.2,37.068V14.131A14.193,14.193,0,0,0,37.038,0Zm9.592,37.038a9.6,9.6,0,0,1-9.592,9.592H14.131a9.6,9.6,0,0,1-9.592-9.592V14.131a9.6,9.6,0,0,1,9.592-9.592H37.068a9.6,9.6,0,0,1,9.592,9.592l-.03,22.907Z"/>
      <path d="M54.163,41A13.178,13.178,0,1,0,67.387,54.163,13.173,13.173,0,0,0,54.163,41Zm0,21.817a8.639,8.639,0,1,1,8.654-8.624A8.672,8.672,0,0,1,54.163,62.817Z" transform="translate(-28.593 -28.593)"/>
      <path d="M122.229,28.3a3.451,3.451,0,0,0-2.36.968,3.268,3.268,0,0,0-.968,2.36,3.451,3.451,0,0,0,.968,2.36,3.4,3.4,0,0,0,2.36.968,3.451,3.451,0,0,0,2.36-.968,3.268,3.268,0,0,0,.968-2.36,3.451,3.451,0,0,0-.968-2.36A3.268,3.268,0,0,0,122.229,28.3Z" transform="translate(-82.921 -19.736)"/>
    </StyledInstagramLogo>
  );
}

export default instagramLogo;
