import React from 'react';
import styled from 'styled-components';

import noreasterWindowImage from '../../../../../assets/images/noreaster-house/noreaster-window.png';

const StyledNoreasterWindow = styled.image`
  overflow: visible;
`;

const noreasterWindow = ( props ) => {
  return(
    <StyledNoreasterWindow
      id="noreasterWindow"
      width="209"
      height="222"
      xlinkHref={noreasterWindowImage}
      transform="matrix(1 0 0 1 303 246)"
    />
  );
}

export default noreasterWindow;
