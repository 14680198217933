import React from 'react';
import styled from 'styled-components';

import crayolaYardImage from '../../../../../assets/images/crayola-house/crayola-yard-thumbnail.png';


const StyledCrayolaYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const crayolaYardThumbnail = ( props ) => {
  return(
      <StyledCrayolaYardThumbnail
        id="crayolaYardThumbnail"
        src={crayolaYardImage}
      />
  );
}

export default crayolaYardThumbnail;
