import React from 'react';
import styled from 'styled-components';

import dougRoofImage from '../../../../../assets/images/doug-house/doug-roof.png';

const StyledDougRoof = styled.image`
  overflow: visible;
`;

const dougRoof = ( props ) => {
  return(
    <StyledDougRoof
      id="dougRoof"
      width="434"
      height="275"
      xlinkHref={dougRoofImage}
      transform="matrix(1 0 0 1 106 14)"
    />
  );
}

export default dougRoof;
