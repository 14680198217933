import React from 'react';
import styled from 'styled-components';

import tropicalWindowImage from '../../../../../assets/images/tropical-house/tropical-window-thumbnail.png';

const StyledTropicalWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const tropicalWindowThumbnail = ( props ) => {
  return(
      <StyledTropicalWindowThumbnail
        id="tropicalWindow"
        src={tropicalWindowImage}
      />
  );
}

export default tropicalWindowThumbnail;
