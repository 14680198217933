/*
 * Arrow Functional Component
 * This component displays the SVG arrow for the button.
 */

import React from 'react';
import styled from 'styled-components';

const StyledArrow = styled.svg`
  fill: ${props => props.theme.colors.white}
`;
const arrow = ( props ) => {
  return(
    <StyledArrow viewBox="0 0 68.4 17.6">
      <polygon className="st0" points="68.4,8.8 47.3,0 47.3,7.3 0,7.3 0,10.3 47.3,10.3 47.3,17.6 "/>
    </StyledArrow>
  );
}

export default arrow;
