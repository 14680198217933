import React from 'react';
import styled from 'styled-components';

import basicWindowImage from '../../../../../assets/images/basic-house/basic-window-thumbnail.png';

const StyledBasicWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BasicWindowThumbnail = ( props ) => {
  return(
      <StyledBasicWindowThumbnail
        id="basicWindow"
        src={basicWindowImage}
      />
  );
}

export default BasicWindowThumbnail;
