/*
 * App Colors
 */

const colors = {
  black: '#000',
  white: '#fff',
  gray: '#ccc',
  grayHover: '#e4e4e4',
  grayActive: '#c8c7c7',
  shadow: 'rgba(000, 000, 000, 0.3)',
  shadowHover: 'rgba(000, 000, 000, 0.4)',
  shadowActive: 'rgba(000, 000, 000, 0.5)',
  orange: '#f27124',
  orangeHover: '#f58643',
  orangeActive: '#f29a65',
  darkOrange: '#c3560b',
  orangeDisabled: '#f7b994',
  brown: '#9c5d32',
  brownHover: '#8e552e',
  brownActive: '#804d29',
  darkBlue: '#2f4752',
  darkBlueHover: '#416271',
  darkBlueActive: '#59889d',
  darkBlueDisabled: '#56869c',
  darkBlueAlternate: '#0f6891',
  darkBlueAlternateHover: '#0a4c6a',
  darkBlueAlternateActive: '#07384e',
  lightBlue: '#adc8d3',
  lighterBlue: '#d2e8ee',
  backdrop: 'rgba(000, 000, 000, 0.7)',
  backdropWhite: 'rgba(255, 255, 255, 0.7)',
}

export default colors;
