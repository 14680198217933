/*
 * Main App Container
 */

import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Route, Switch, Redirect } from 'react-router-dom';
import { detect } from 'detect-browser';


import { main } from './styles/Main';
import backgroundImage from './assets/images/background_snow@2x.png';
import SnowParticles from './components/SnowParticles/SnowParticles';
import Welcome from './containers/Welcome/Welcome';
import HouseBuilder from './containers/HouseBuilder/HouseBuilder';
import Share from './containers/Share/Share';
import Tour from './containers/Tour/Tour';
import BrowserWarning from './components/BrowserWarning/BrowserWarning';
import BROWSER_LIST from './data/constants/BROWSER_LIST';


const StyledApp = styled.main`
  height: 100vh;
  width: 100vw;
  background-image: url(${backgroundImage});
  background-size: contain;
  background-color: ${props => props.theme.colors.lightBlue};
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;

  ${props => props.theme.media.large`
    background-size: cover;
  `}

  ${props => props.theme.orientation.landscape`
    min-height: 700px;
  `}

  ${props => props.theme.media.large`
    position: relative;
  `}
`;


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      browserList: BROWSER_LIST,
      browserFlag: false,
      browserName: null,
    }
  }

  componentDidMount() {
    const browser = detect();
    this.checkBrowser(browser);
  }

  checkBrowser(browser) {
    const browserVersion = browser.version.split('.');
    const browserNum = browserVersion[0];
    this.setState({browserName: browser.name});

    switch (browser && browser.name) {
      case 'chrome':
        if (browserNum >= '68') { this.setState({ browserFlag: false }) }
        break;
      case 'edge':
        if (browserNum >= '11') { this.setState({ browserFlag: false }) }
        break;
      case 'firefox':
        if (browserNum >= '61') { this.setState({ browserFlag: false }) }
        break;
      case 'safari':
        if (browserNum >= '11') { this.setState({ browserFlag: false }) }
        break;
      case 'ios':
        this.setState({ browserFlag: false })
        break;
      case 'android':
        this.setState({ browserFlag: false })
        break;
      case 'crios':
        this.setState({ browserFlag: false })
        break;
      case 'fban':
        this.setState({ browserFlag: false })
        break;
      case 'fbav':
        this.setState({ browserFlag: false })
        break;
      case 'facebook':
        this.setState({ browserFlag: false })
        break;
      case 'fxios':
        this.setState({ browserFlag: false })
        break;
      case 'instagram':
        this.setState({ browserFlag: false })
        break;
      default:
        this.setState({ browserFlag: true })
    }
  }
  render() {
    let content = null;

    if (!this.state.browserFlag) {
      content = (
        <React.Fragment>
          <SnowParticles />
          {/* Commented out this switch statement since we no longer want users to see this part of the app */}
          <Switch>
            <Route path="/share" component={Share} />
            <Route path="/build" component={HouseBuilder} />
            <Route path="/watch" component={Welcome} />
            <Route path="/tour" exact component={Tour}/>
            <Route path="/" exact component={Welcome}/>
            <Redirect to="/" />
          </Switch>
          {/* <Switch>
            <Route path="/share" render={() => (
              <Redirect to="/tour" />
            )} />
            <Route path="/build" render={() => (
              <Redirect to="/tour" />
            )} />
            <Route path="/watch" render={() => (
              <Redirect to="/tour" />
            )} />
            <Route path="/tour" exact component={Tour}/>
            <Route path="/" render={() => (
              <Redirect to="/tour" />
            )} />

          </Switch> */}
        </React.Fragment>
      );
    }
    return (
      <ThemeProvider theme={main}>
        <StyledApp>
          <BrowserWarning
            browserItems={this.state.browserList}
            show={this.state.browserFlag} />
          {content}
        </StyledApp>
      </ThemeProvider>
    );
  }
}

export default App;
