import React from 'react';
import styled from 'styled-components';

import dogBackground from '../../../../../assets/images/dog-house/doghouse-background.png';

const StyledDogLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const dogLocation = ( props ) => {
  return(
    <StyledDogLocation
      id="dogLocation"
      width="600"
      height="600"
      xlinkHref={dogBackground}
    />
  );
}

export default dogLocation;
