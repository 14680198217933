import React from 'react';
import styled from 'styled-components';

import candyRoofImage from '../../../../../assets/images/candyland-house/candyland-roof.png';

const StyledCandyRoof = styled.image`
  overflow: visible;
`;

const candyRoof = ( props ) => {
  return(
    <StyledCandyRoof
      id="candyRoof"
      width="432"
      height="192"
      xlinkHref={candyRoofImage}
      transform="matrix(1 0 0 1 105 71)"
    />
  );
}

export default candyRoof;
