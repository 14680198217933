import React from 'react';
import styled from 'styled-components';

const StyledTwitterLogo = styled.svg`
  fill: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  max-width: 24px;
  max-height: 31px;

  ${props => props.theme.media.largeSmall`
    max-width: 37px;
    max-height: 31px;
  `}
`;

const twitterLogo = ( props ) => {
  return(
    <StyledTwitterLogo viewBox="0 0 54.21 44.56">
      <path d="M20.2,44.45c-2.4,0-4.8-0.19-7.18-0.57C9.58,43.2,6.27,42,3.2,40.31L0,38.56l3.48-1.14
      	c3.8-1.24,6.11-2.02,8.96-3.24c-2.93-1.43-5.14-4-6.14-7.1L5.5,24.62l0.66,0.11c-0.56-0.57-1.07-1.19-1.51-1.86
      	c-1.34-2.01-2.02-4.39-1.94-6.8l0.16-2.14l1.28,0.5c-0.54-1.02-0.93-2.12-1.16-3.25c-0.55-2.85-0.08-5.8,1.32-8.34l1.11-2.05
      	l1.49,1.8c4.43,5.51,10.81,9.1,17.81,10.02c-0.31-1.91-0.08-3.87,0.65-5.66c0.88-2.06,2.39-3.79,4.31-4.95
      	c2.26-1.36,4.88-2,7.51-1.84c2.73,0.14,5.34,1.21,7.38,3.04c0.92-0.24,1.82-0.54,2.7-0.9c0.6-0.22,1.27-0.48,2.1-0.77l3.09-1.07
      	l-2.02,5.76c0.14-0.02,0.27-0.02,0.41-0.03l3.31-0.14L52.2,8.73c-0.06,0.09-0.12,0.17-0.19,0.25c-0.16,0.24-0.36,0.53-3.03,4.11
      	c-0.69,0.95-1.02,2.12-0.94,3.29c0.3,4.24-0.28,8.5-1.69,12.51c-1.24,3.37-3.28,6.39-5.94,8.81c-3.53,3.09-7.83,5.16-12.44,6.02
      C25.42,44.21,22.81,44.46,20.2,44.45L20.2,44.45z"/>
    </StyledTwitterLogo>
  );
}

export default twitterLogo;
