import React from 'react';
import styled from 'styled-components';

import dougDoorImage from '../../../../../assets/images/doug-house/doug-door.png';


const StyledDougDoor = styled.image`
  overflow: visible;
`;

const dougDoor = ( props ) => {
  return(
    <StyledDougDoor
      id="dougDoor"
      width="213"
      height="389"
      xlinkHref={dougDoorImage}
      transform="matrix(1 0 0 1 123 81)"
    />
  );
}

export default dougDoor;
