import React from 'react';
import styled from 'styled-components';

import catWindowImage from '../../../../../assets/images/cat-house/cat-window-thumbnail.png';

const StyledCatWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CatWindowThumbnail = ( props ) => {
  return(
      <StyledCatWindowThumbnail
        id="catWindow"
        src={catWindowImage}
      />
  );
}

export default CatWindowThumbnail;
