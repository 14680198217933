import React from 'react';
import styled from 'styled-components';

import hanukkahWindowImage from '../../../../../assets/images/hanukkah-house/hanukkah-window-thumbnail.png';

const StyledHanukkahWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const HanukkahWindowThumbnail = ( props ) => {
  return(
      <StyledHanukkahWindowThumbnail
        id="hanukkahWindow"
        src={hanukkahWindowImage}
      />
  );
}

export default HanukkahWindowThumbnail;
