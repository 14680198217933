import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BasicYard from './HouseParts/Yards/BasicYard';
import CandyYard from './HouseParts/Yards/CandyYard';
import DogYard from './HouseParts/Yards/DogYard';
import NoreasterYard from './HouseParts/Yards/NoreasterYard';
import TropicalYard from './HouseParts/Yards/TropicalYard';
import TraditionalYard from './HouseParts/Yards/TraditionalYard';
import WorkshopYard from './HouseParts/Yards/WorkshopYard';
import CrayolaYard from './HouseParts/Yards/CrayolaYard';
import CatYard from './HouseParts/Yards/CatYard';
import PostureYard from './HouseParts/Yards/PostureYard';
import HanukkahYard from './HouseParts/Yards/HanukkahYard';
import DougYard from './HouseParts/Yards/DougYard';

class HousePartYard extends Component {
  render() {
    let part = null;

    switch(this.props.type) {
      case ('basic-yard'):
        part = <BasicYard />;
        break;
      case ('candy-yard'):
        part = <CandyYard />;
        break;
      case ('dog-yard'):
        part = <DogYard />;
        break;
      case ('noreaster-yard'):
        part = <NoreasterYard />;
        break;
      case ('tropical-yard'):
        part = <TropicalYard />;
        break;
      case ('traditional-yard'):
        part = <TraditionalYard />;
        break;
      case ('workshop-yard'):
        part = <WorkshopYard />;
        break;
      case ('crayola-yard'):
        part = <CrayolaYard />;
        break;
      case ('cat-yard'):
        part = <CatYard />;
        break;
      case ('posture-yard'):
        part = <PostureYard />;
        break;
      case ('hanukkah-yard'):
        part = <HanukkahYard />;
        break;
      case ('doug-yard'):
        part = <DougYard />;
        break;
      default:
        part = null;
    }
    return part;
  }
}

HousePartYard.propTypes = {
  type: PropTypes.string.isRequired,
}
export default HousePartYard;
