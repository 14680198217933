import React from 'react';
import styled from 'styled-components';

import basicYardImage from '../../../../../assets/images/basic-house/basic-yard.png';

const StyledBasicYard = styled.image`
    overflow: visible;
`;

const basicYard = ( props ) => {
  return(
    <StyledBasicYard
      id="basicYard"
      width="593"
      height="376"
      xlinkHref={basicYardImage}
      transform="matrix(1 0 0 1 -1 225)"
    />
  );
}

export default basicYard;
