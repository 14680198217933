import React from 'react';
import styled from 'styled-components';

import workshopDoorImage from '../../../../../assets/images/workshop-house/workshop-door-thumbnail.png';

const StyledNoreasterDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const workshopDoorThumbnail = ( props ) => {
  return(
    <StyledNoreasterDoorThumbnail
      id="workshopDoorThumbnail"
      src={workshopDoorImage}
    />
  );
}

export default workshopDoorThumbnail;
