import React from 'react';
import styled from 'styled-components';

import dogWindowImage from '../../../../../assets/images/dog-house/doghouse-window.png';

const StyledDogWindow = styled.image`
  overflow: visible;
`;

const dogWindow = ( props ) => {
  return(
    <StyledDogWindow
      id="dogWindow"
      width="212"
      height="225"
      xlinkHref={dogWindowImage}
      transform="matrix(1 0 0 1 300 244)"
    />
  );
}

export default dogWindow;
