import React from 'react';
import styled from 'styled-components';

const StyledFoundationItem = styled.div`
  max-width: 200px;
  max-height: 200px;
  margin: 1em;
  border: 1px solid ${props => props.theme.colors.orange};
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.5s;
  padding: 0.3em;

  transform: ${props => props.activeItem ? 'scale(1.05)' : 'none'};
  box-shadow: ${props => props.activeItem ? '0 2px 25px' + props.theme.colors.orange  : 'none'};


  img {
    width: 100%;
  }

  p {
    text-align: center;
    text-transform: capitalize;
    font-size: 0.8em;
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.orangeHover};
    transform: ${props => props.activeItem ? 'scale(1.06)' : 'scale(1.03)'};
    box-shadow: ${props => props.activeItem ? '0 5px 30px ' + props.theme.colors.orangeHover : '0 2px 3px ' + props.theme.colors.shadow };

  }

  &:active {
    border-color: ${props => props.theme.colors.orangeActive};
  }
`;

const foundationItem = ( props ) => {
  return(
    <StyledFoundationItem
      onClick={props.clickedItem}
      activeItem={props.activeItem}>
      {props.children}
    </StyledFoundationItem>
  );
}

export default foundationItem;
