import React from 'react';
import styled from 'styled-components';

import tropicalWindowImage from '../../../../../assets/images/tropical-house/tropical-window.png';

const StyledTropicalWindow = styled.image`
  overflow: visible;
`;

const tropicalWindow = ( props ) => {
  return(
    <StyledTropicalWindow
      id="tropicalWindow"
      width="210"
      height="222"
      xlinkHref={tropicalWindowImage}
      transform="matrix(1 0 0 1 303 246)"
    />
  );
}

export default tropicalWindow;
