import React from 'react';
import styled from 'styled-components';

import dougWindowImage from '../../../../../assets/images/doug-house/doug-window-thumbnail.png';

const StyledDougWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DougWindowThumbnail = ( props ) => {
  return(
      <StyledDougWindowThumbnail
        id="dougWindow"
        src={dougWindowImage}
      />
  );
}

export default DougWindowThumbnail;
