import React from 'react';
import styled from 'styled-components';

import crayolaDoorImage from '../../../../../assets/images/crayola-house/crayola-door.png';


const StyledCrayolaDoor = styled.image`
  overflow: visible;
`;

const crayolaDoor = ( props ) => {
  return(
    <StyledCrayolaDoor
      id="crayolaDoor"
      width="189"
      height="392"
      xlinkHref={crayolaDoorImage}
      transform="matrix(1 0 0 1 125 81)"
    />
  );
}

export default crayolaDoor;
