import React from 'react';
import styled from 'styled-components';

import noreasterYardImage from '../../../../../assets/images/noreaster-house/noreaster-yard.png';

const StyledNoreasterYard = styled.image`
    overflow: visible;
`;

const noreasterYard = ( props ) => {
  return(
    <StyledNoreasterYard
      id="noreasterYard"
      width="491"
      height="273"
      xlinkHref={noreasterYardImage}
      transform="matrix(1 0 0 1 109 327)"
    />
  );
}

export default noreasterYard;
