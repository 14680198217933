import React from 'react';
import styled from 'styled-components';

import catRoofImage from '../../../../../assets/images/cat-house/cat-roof-thumbnail.png';

const StyledCatRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const catRoofThumbnail = ( props ) => {
  return(
    <StyledCatRoofThumbnail
      id="catRoofThumbnail"
      src={catRoofImage}
    />
  );
}

export default catRoofThumbnail;
