import React from 'react';
import styled from 'styled-components';

import noreasterBackground from '../../../../../assets/images/noreaster-house/noreaster-background-thumbnail.png';

const StyledNoreasterLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const noreasterLocationThumbnail = ( props ) => {
  return(
      <StyledNoreasterLocationThumbnail
        id="noreasterLocationThumbnail"
        src={noreasterBackground}
      />
  );
}

export default noreasterLocationThumbnail;
