import React from 'react';
import styled from 'styled-components';

import postureYardImage from '../../../../../assets/images/posture-house/posture-yard-thumbnail.png';


const StyledPostureYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const postureYardThumbnail = ( props ) => {
  return(
      <StyledPostureYardThumbnail
        id="postureYardThumbnail"
        src={postureYardImage}
      />
  );
}

export default postureYardThumbnail;
