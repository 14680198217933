import React from 'react';
import styled from 'styled-components';

import janetWeisFoundationImage from '../../../../../assets/images/foundation/janetweis-foundation.png';


const StyledJanetWeisFoundation = styled.image`
  overflow: visible;
`;

const janetWeisFoundation = ( props ) => {
  return(
    <StyledJanetWeisFoundation
      id="janetWeisLogo"
      width="395"
      height="196"
      xlinkHref={janetWeisFoundationImage}
      transform="matrix(0.48 0 0 0.48 373.44 470.4)"
    />
  );
}

export default janetWeisFoundation;
