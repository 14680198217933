/*
 * App Animations 
 */
import { keyframes } from 'styled-components';

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const bounceRight = keyframes`
  0%,
  30%,
  50% {
      transform: translateX(0) scaleX(1)
  }
  10% {
      transform: translateX(-10px) scaleX(0.85)
  }
  20% {
      transform: translateX(20px) scaleX(1)
  }
  40% {
      transform: translateX(15px) scaleX(0.9)
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const typedjsBlink = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1;
  }
`;

const moveProgress = keyframes`
  0% {
    background-position: 0 0
  }

  100% {
    background-position: 50px 50px;
  }
`;

const animations = {
  spinnerAnimation: spinnerAnimation,
  bounceRight: bounceRight,
  fadeIn: fadeIn,
  typedjsBlink: typedjsBlink,
  moveProgress: moveProgress,
}

export default animations;
