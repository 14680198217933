import React from 'react';
import styled from 'styled-components';

import crayolaRoofImage from '../../../../../assets/images/crayola-house/crayola-roof.png';

const StyledCrayolaRoof = styled.image`
  overflow: visible;
`;

const crayolaRoof = ( props ) => {
  return(
    <StyledCrayolaRoof
      id="crayolaRoof"
      width="418"
      height="183"
      xlinkHref={crayolaRoofImage}
      transform="matrix(1 0 0 1 109 70)"
    />
  );
}

export default crayolaRoof;
