import React from 'react';
import styled from 'styled-components';

import workshopWindowImage from '../../../../../assets/images/workshop-house/workshop-window-thumbnail.png';

const StyledWorkshopWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const workshopWindowThumbnail = ( props ) => {
  return(
      <StyledWorkshopWindowThumbnail
        id="workshopWindow"
        src={workshopWindowImage}
      />
  );
}

export default workshopWindowThumbnail;
