import React from 'react';
import styled from 'styled-components';


import Backdrop from '../Backdrop/Backdrop';
import PostureSpinner from '../Spinner/PostureSpinner';


const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 401;
  flex-flow: row wrap;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const loading = ( props ) => {
  return(
    <React.Fragment>
      <Backdrop show={props.show} background={props.background} />
      <StyledLoading>
        <PostureSpinner>
          {props.children}
        </PostureSpinner>
      </StyledLoading>
    </React.Fragment>
  );
}

export default loading;
