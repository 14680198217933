import React from 'react';
import styled from 'styled-components';

import basicYardImage from '../../../../../assets/images/basic-house/basic-yard-thumbnail.png';


const StyledBasicYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const basicYardThumbnail = ( props ) => {
  return(
      <StyledBasicYardThumbnail
        id="basicYardThumbnail"
        src={basicYardImage}
      />
  );
}

export default basicYardThumbnail;
