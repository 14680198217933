import React from 'react';
import styled from 'styled-components';

import tropicalRoofImage from '../../../../../assets/images/tropical-house/tropical-roof-thumbnail.png';

const StyledTropicalRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const tropicalRoofThumbnail = ( props ) => {
  return(
      <StyledTropicalRoofThumbnail
        id="tropicalRoofThumbnail"
        src={tropicalRoofImage}
      />
  );
}

export default tropicalRoofThumbnail;
