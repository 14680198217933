import React from 'react';
import styled from 'styled-components';

import tropicalDoorImage from '../../../../../assets/images/tropical-house/tropical-door.png';


const StyledTropicalDoor = styled.image`
  overflow: visible;
`;

const tropicalDoor = ( props ) => {
  return(
    <StyledTropicalDoor
      id="tropicalDoor"
      width="189"
      height="389"
      xlinkHref={tropicalDoorImage}
      transform="matrix(1 0 0 1 121 81)"
    />
  );
}

export default tropicalDoor;
