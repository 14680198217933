import React from 'react';
import styled from 'styled-components';

import dougLocationImage from '../../../../../assets/images/doug-house/doug-background.png';

const StyledDougLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const dougLocation = ( props ) => {
  return(
    <StyledDougLocation
      id="dougLocation"
      width="600"
      height="600"
      xlinkHref={dougLocationImage}
    />
  );
}

export default dougLocation;
