import React from 'react';
import styled from 'styled-components';

import crayolaRoofImage from '../../../../../assets/images/crayola-house/crayola-roof-thumbnail.png';

const StyledCrayolaRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const crayolaRoofThumbnail = ( props ) => {
  return(
    <StyledCrayolaRoofThumbnail
      id="crayolaRoofThumbnail"
      src={crayolaRoofImage}
    />
  );
}

export default crayolaRoofThumbnail;
