import React from 'react';
import styled from 'styled-components';

import hanukkahDoorImage from '../../../../../assets/images/hanukkah-house/hanukkah-door-thumbnail.png';

const StyledHanukkahDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const hanukkahDoorThumbnail = ( props ) => {
  return(
    <StyledHanukkahDoorThumbnail
      id="hanukkahDoorThumbnail"
      src={hanukkahDoorImage}
    />
  );
}

export default hanukkahDoorThumbnail;
