import React from 'react';
import styled from 'styled-components';

import traditionalRoofImage from '../../../../../assets/images/traditional-house/traditional-roof.png';

const StyledTraditionalRoof = styled.image`
  overflow: visible;
`;

const traditionalRoof = ( props ) => {
  return(
    <StyledTraditionalRoof
      id="traditionalRoof"
      width="419"
      height="225"
      xlinkHref={traditionalRoofImage}
      transform="matrix(1 0 0 1 110 45)"
    />
  );
}

export default traditionalRoof;
