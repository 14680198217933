import React from 'react';
import styled from 'styled-components';

import workshopRoofImage from '../../../../../assets/images/workshop-house/workshop-roof.png';

const StyledWorkshopRoof = styled.image`
  overflow: visible;
`;

const workshopRoof = ( props ) => {
  return(
    <StyledWorkshopRoof
      id="workshopRoof"
      width="416"
      height="187"
      xlinkHref={workshopRoofImage}
      transform="matrix(1 0 0 1 112 66)"
    />
  );
}

export default workshopRoof;
