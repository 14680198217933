/*
 * House Builder Stateful Component
 * This Component controls the build page and passes it's state down to the
 * components rendered on the page.
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { svgAsPngUri } from 'save-svg-as-png';
//import { svgAsPngUri } from '../../vendor/saveSvgAsPngCustom';
import AWS from 'aws-sdk';


import HOUSE_PARTS from '../../data/constants/HOUSE_PARTS';
import NAV_MENU from '../../data/constants/NAV_MENU';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import HousePartyLogoHorizontal from '../../components/Logo/HousePartyLogoHorizontal';
import HousePartyLogo from '../../components/Logo/HousePartyLogo';
import HouseControls from '../../components/House/HouseControls/HouseControls';
import HouseCarousel from '../../components/House/HouseCarousel/HouseCarousel';
import House from '../../components/House/House';
import CandyCane from '../../components/CandyCane/CandyCane';
import Loading from '../../components/UI/Loading/Loading';
import FoundationItems from '../../components/Foundation/FoundationItems';
import TypedComponent from '../../components/Typed/Typed.js';
import Navbar from '../../components/Navigation/Navbar/Navbar';

const StyledBuildSection = styled.section`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;

  ${props => props.theme.media.large`
    overflow: hidden;
  `}
`;

const StyledBuildSectionInner = styled.div`
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: column;

  ${props => props.theme.media.medium`
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  `}

  p {
    line-height: 1.5;
  }

  h2 {
    text-align: center;
  }
`;

const StyledLogoWrapper = styled.div`
  width: 100%;
  display: ${props => props.loading ? 'none' : 'flex'};
  flex-flow: row wrap;
  padding: 1em;
  box-sizing: border-box;
  justify-content: center;
  min-height: 60px;

  ${props => props.theme.media.large`
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: min-content;
    max-width: calc(40% - 1em);
    margin-right: auto;
    margin-bottom: 5em;
    margin-left: auto;
  `}
`;

const StyledHouseBuilderWrapper = styled.div`
  width: 100%;
  display: ${props => props.loading ? 'none' : 'flex'};
  flex-flow: column;
  z-index: 100;
  text-align: center;

  ${props => props.theme.media.large`
    height: min-content;
    max-width: calc(50% - 2em);
    padding: 0;
    display: block;
  `}

  ${props => props.theme.height.medium`
    #House {
      max-width: 60%;
    }
  `}
`;

const StyledCandyCaneWrapper = styled.div`
  position: absolute;
  left: 10%;
  width: 50%;
  z-index: -1;
  overflow: hidden;
  height: 40%;
  display: ${props => props.loading ? 'none' : 'block'};
  top: 68%;
  margin-left: 5em;
  max-height: 310px;


  ${props => props.theme.media.xlarge`
    left: 25%;
  `}
`;

const StyledButtonWrapper = styled.div`
  button {
    max-width: 100%;
  }
`;

/* Prepare the House Menu items */
const HOUSE_MENU = HOUSE_PARTS.map((menuItem) => {
  return {
    label: menuItem.part,
    icon: menuItem.icon,
  };
}).filter((menuItem) => {
  if (menuItem.label !== 'foundation') {
    return menuItem;
  }
});


class HouseBuilder extends Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();


    const carouselItemObj = HOUSE_PARTS.find((element) => {
      return ('location' === element.part) ? element : null;
    });

    const foundationItemsObj = HOUSE_PARTS.find((element) => {
      return ('foundation' === element.part) ? element : null;
    });

    this.state = {
      house: {
        location: 'basic-location',
        roof: 'basic-roof',
        door: 'basic-door',
        window: 'basic-window',
        yard: 'basic-yard',
        foundation: 'unitedway-foundation',
      },
      menu: HOUSE_MENU,
      carouselItems: carouselItemObj.parts,
      activePart: 'location',
      isTablet: false,
      isDesktop: false,
      loading: false,
      uri: null,
      currentDate: Date.now(),
      fileName: '_' + Math.floor(Math.random() * 10001) + '_posture_house_party.png',
      awsBucketName: 'posture-house-party',
      awsBucketRegion: 'us-west-2',
      awsIdentityPoolId: 'us-west-2:f66d7de9-ac6a-4500-905a-70b457b9a9d1',
      showModal: true,
      foundationItems: foundationItemsObj.parts,
      activeFoundationItem: 'unitedway-foundation',
      navItems: NAV_MENU,
      navProgress: this.calculateNavProgress(),
      svgURI: null,
    };
  }

  componentDidMount() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
    this.configureAWSSDK();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }


  resizeHandler = () => {
    this.setState({
      isTablet: window.innerWidth >= 768,
      isDesktop: window.innerWidth >= 1024,
    });
  }

  clickedControlItemHandler = (item) => {
    const carouselItemObj = HOUSE_PARTS.find((element) => {
      return (item === element.part) ? element : null;
    });

    this.setState((prevState) => {
      return {
        activePart: item,
        carouselItems: carouselItemObj.parts,
      };
    });
  }

  clickedFoundationItemHandler = (itemSlug) => {
    // Slug contains key so split it here to get the key.
    const itemArr = itemSlug.split('-');
    const itemKey = itemArr[1];
    const newFoundation = {...this.state.house};

    newFoundation[itemKey] = itemSlug;
    this.setState({
      house: newFoundation,
      activeFoundationItem: itemSlug,
    });
  }

  clickedCarouselPartHandler = (itemSlug) => {
    // Slug contains key so split it here to get the key.
    const itemArr = itemSlug.split('-');
    const itemKey = itemArr[1];
    const newParts = {...this.state.house};

    newParts[itemKey] = itemSlug;
    this.setState({house: newParts});
  }

  shareClickHandler = () => {
    this.setState({loading: true});

    // Convert SVG to PNG URI

    svgAsPngUri(this.svgRef.current, {
      height: 600,
      width: 600,
    }, (uri) => {
      this.setState({svgURI: uri});
    });

    setTimeout(() => {
      svgAsPngUri(this.svgRef.current, {
        height: 600,
        width: 600,
      }, (uri) => {

        this.setState({svgURI: uri}, () => {
          this.uploadAWSImage(uri);
        });

        // Trigger AWS upload
       // s3.putObject({
       //   Key: this.state.currentDate + this.state.fileName,
       //   Body: this.convertURIToBlob(uri),
       //   ACL: 'public-read',
       //   // ContentEncoding: 'base64',
       //   ContentType: 'image/png',
       //   CacheControl: 'public',
       // }, (err, data) => {
       //   if (err) {
       //     return console.log('There was an error uploading your photo: ', err.message);
       //   } else {
       //
       //     console.log('uploadSuccesful');
       //     const houseImageURL = s3.endpoint.href + this.state.awsBucketName + '/' + this.state.currentDate + this.state.fileName;
       //
       //     this.shareHouse(houseImageURL);
       //   }
       // });
      });
    }, 4000);
  }

  closeModalHandler = () => {
    this.setState({showModal: false});
  }

  navClickHandler = (item, event) => {
    if (item === 'share') {
      event.preventDefault();
      this.shareClickHandler();
    }
  }

  convertURIToBlob(uri) {
    const byteString = window.atob(uri.split(',')[1]);
    const mimeString = uri.split(',')[0].split(':')[1].split(';')[0]
    const buffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
     intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([buffer], {type: mimeString});
  }

  configureAWSSDK() {
    AWS.config.update({
      region: this.state.awsBucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.state.awsIdentityPoolId,
      }),
    });
  }

  uploadAWSImage(uri) {
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: {Bucket: this.state.awsBucketName},
      useAccelerateEndpoint: false,
    });

    const buf = new Buffer(uri.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // console.log(buf);
    // Trigger AWS upload
    let putObjectPromise = s3.putObject({
      Key: this.state.currentDate + this.state.fileName,
      // Body: this.convertURIToBlob(uri),
      Body: buf,
      ACL: 'public-read',
      // ContentEncoding: 'base64',
      ContentType: 'image/png',
      CacheControl: 'public',
    }).promise();

    putObjectPromise.then((data) => {
      const houseImageURL = s3.endpoint.href + this.state.awsBucketName + '/' + this.state.currentDate + this.state.fileName;

      this.shareHouse(houseImageURL);
    }).catch((err) => {
      console.log(err);
    });
  }

  calculateNavProgress() {
    const path = this.props.history.location.pathname;
    let progress = 0;

    switch(path) {
      case ('/build'):
        progress = (2 / 3) * 100;
      break;
      case ('/share'):
        progress = (3 / 3) * 100;
      break;
      default:
        progress = 0;
    }
    return progress;
  }

  shareHouse(url) {
    this.props.history.push({
      pathname: '/share',
      search: '?image=' + url,
    });
  }


  render() {
    const isDesktop = this.state.isDesktop;
    let loading = null;
    const HouseComponents = (
      <React.Fragment>
        <House
          location={this.state.house.location}
          roof={this.state.house.roof}
          door={this.state.house.door}
          window={this.state.house.window}
          yard={this.state.house.yard}
          foundation={this.state.house.foundation}
          ref={this.svgRef}
        />

        <HouseCarousel
          activePart={this.state.activePart}
          carouselItems={this.state.carouselItems}
          clickedPart={this.clickedCarouselPartHandler}
        />
        <HouseControls
          menu={this.state.menu}
          clickedItem={this.clickedControlItemHandler}
          activeItem={this.state.activePart}/>
      </React.Fragment>
        );

    let content = (
      <React.Fragment>
        <StyledLogoWrapper loading={this.state.loading}>
          <HousePartyLogoHorizontal />
        </StyledLogoWrapper>
        <StyledHouseBuilderWrapper loading={this.state.loading}>
          {HouseComponents}
          <StyledButtonWrapper>
            <Button
              margin="0"
              to={{pathname: '/share', state: {house: this.state.house}}}
              clicked={this.shareClickHandler}>Share your house</Button>
          </StyledButtonWrapper>
        </StyledHouseBuilderWrapper>
      </React.Fragment>
    );

    if (isDesktop) {
      content = (
        <React.Fragment>
          <StyledLogoWrapper loading={this.state.loading}>
            <HousePartyLogo />
            <Button
              margin="2em"
              to={{pathname: '/share'}}
              clicked={this.shareClickHandler}
            >Share your house</Button>
          </StyledLogoWrapper>
          <StyledHouseBuilderWrapper loading={this.state.loading}>
            {HouseComponents}
          </StyledHouseBuilderWrapper>
          <StyledCandyCaneWrapper loading={this.state.loading}>
            <CandyCane />
          </StyledCandyCaneWrapper>
        </React.Fragment>
      );
    }


    if (this.state.loading) {
      loading = (
        <Loading show>
          <TypedComponent
            strings={[
              'Creating your house...',
              'Adding the foundation',
              'Adding the walls',
              'Adding the roof',
              'Adding the final touches...',
            ]}/>
        </Loading>
      );

    }
    return(
      <StyledBuildSection>
        <Navbar
          progress={this.state.navProgress}
          items={this.state.navItems}
          clicked={this.navClickHandler}/>
        <StyledBuildSectionInner>
          <Modal
            show={this.state.showModal}
            modalClosed={this.closeModalHandler}>
            <h2>Select a Foundation below: </h2>
            <p>The Posture House party is intended to provide holiday cheer for all, but the lucky non-profit organization with the most houses shared using #posturehouseparty will be awarded a cash donation in addition to a sleigh full of elf hours assisting their design, video, or web needs totaling $5,000.</p>
            <FoundationItems
              items={this.state.foundationItems}
              clickedItem={this.clickedFoundationItemHandler}
              activeItem={this.state.activeFoundationItem}/>
            <Button clicked={this.closeModalHandler}>Start Building!</Button>
          </Modal>
          {loading}
          {content}
        </StyledBuildSectionInner>
      </StyledBuildSection>
    );
  }
}

export default HouseBuilder;
