import React from 'react';
import styled from 'styled-components';

import crayolaDoorImage from '../../../../../assets/images/crayola-house/crayola-door-thumbnail.png';

const StyledCrayolaDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const crayolaDoorThumbnail = ( props ) => {
  return(
    <StyledCrayolaDoorThumbnail
      id="crayolaDoorThumbnail"
      src={crayolaDoorImage}
    />
  );
}

export default crayolaDoorThumbnail;
