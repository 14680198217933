import React from 'react';
import styled from 'styled-components';

import HousePartDoor from './HousePart/HousePartDoor';
import HousePartWindow from './HousePart/HousePartWindow';
import HousePartRoof from './HousePart/HousePartRoof';
import HousePartYard from './HousePart/HousePartYard';
import HousePartLocation from './HousePart/HousePartLocation';
import HousePartFoundation from './HousePart/HousePartFoundation';

const StyledHouse = styled.svg`
  width: 100%;

  ${props => props.theme.media.medium`
    max-width: 70%;
    margin: 0 auto;
  `}

  ${props => props.theme.media.large`
    max-width: 90%;
  `}
`;

const house = React.forwardRef((props, ref) => {
  return(
    <StyledHouse id="House" viewBox="0 0 600 600" ref={ref}>
      <g id="Background">
        <HousePartLocation type={props.location} />
      </g>
      <g id="Windows">
        <HousePartWindow type={props.window} />
      </g>
      <g id="Door">
        <HousePartDoor type={props.door}/>
      </g>
      <g id="Yard">
        <HousePartYard type={props.yard} />
      </g>
      <g id="Roof">
        <HousePartRoof type={props.roof} />
      </g>
      <g id="Foundation">
        <HousePartFoundation type={props.foundation} />
      </g>
    </StyledHouse>
  );
});

export default house;
