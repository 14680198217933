import React from 'react';
import styled from 'styled-components';

import tropicalDoorImage from '../../../../../assets/images/tropical-house/tropical-door-thumbnail.png';

const StyledNoreasterDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const tropicalDoorThumbnail = ( props ) => {
  return(
    <StyledNoreasterDoorThumbnail
      id="tropicalDoorThumbnail"
      src={tropicalDoorImage}
    />
  );
}

export default tropicalDoorThumbnail;
