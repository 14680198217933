import React from 'react';
import styled from 'styled-components';

import tropicalYardImage from '../../../../../assets/images/tropical-house/tropical-yard-thumbnail.png';


const StyledTropicalYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const tropicalYardThumbnail = ( props ) => {
  return(
      <StyledTropicalYardThumbnail
        id="tropicalYardThumbnail"
        src={tropicalYardImage}
      />
  );
}

export default tropicalYardThumbnail;
