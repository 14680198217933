import React from 'react';
import styled from 'styled-components';

import workshopYardImage from '../../../../../assets/images/workshop-house/workshop-yard-thumbnail.png';


const StyledWorkshopYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const workshopYardThumbnail = ( props ) => {
  return(
      <StyledWorkshopYardThumbnail
        id="workshopYardThumbnail"
        src={workshopYardImage}
      />
  );
}

export default workshopYardThumbnail;
