import React from 'react';
import styled from 'styled-components';

const StyledHouseCarouselItem = styled.div`
  ${'' /* width: 80px; */}
  height: 80px;
  border: 1px solid ${props => props.theme.colors.orange};
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
  margin: 0 0.2em;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.5s;


  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.orangeHover};
    transform: scale(1.03);
    box-shadow: 0 2px 3px ${props => props.theme.colors.shadow};

  }

  &:active {
    border-color: ${props => props.theme.colors.orangeActive};
  }
`;

const houseCarouselItem = ( props ) => {
  return(
    <StyledHouseCarouselItem onClick={props.clickedPart}>
      {props.children}
    </StyledHouseCarouselItem>
  );
}

export default houseCarouselItem;
