import React from 'react';
import styled from 'styled-components';

import basicRoofImage from '../../../../../assets/images/basic-house/basic-roof.png';

const StyledBasicRoof = styled.image`
  overflow: visible;
`;

const basicRoof = ( props ) => {
  return(
    <StyledBasicRoof
      id="basicRoof"
      width="413"
      height="174"
      xlinkHref={basicRoofImage}
      transform="matrix(1 0 0 1 110 74)"
    />
  );
}

export default basicRoof;
