import React from 'react';
import styled from 'styled-components';

import catBackground from '../../../../../assets/images/cat-house/cat-background-thumbnail.png';

const StyledCatLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const catLocationThumbnail = ( props ) => {
  return(
      <StyledCatLocationThumbnail
        id="catLocationThumbnail"
        src={catBackground}
      />
  );
}

export default catLocationThumbnail;
