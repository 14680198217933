import React from 'react';
import styled from 'styled-components';

import tropicalBackground from '../../../../../assets/images/tropical-house/tropical-background.png';

const StyledTropicalLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const tropicalLocation = ( props ) => {
  return(
    <StyledTropicalLocation
      id="tropicalLocation"
      width="600"
      height="600"
      xlinkHref={tropicalBackground}
    />
  );
}

export default tropicalLocation;
