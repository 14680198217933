import React from 'react';
import styled from 'styled-components';

import catDoorImage from '../../../../../assets/images/cat-house/cat-door.png';


const StyledCatDoor = styled.image`
  overflow: visible;
`;

const catDoor = ( props ) => {
  return(
    <StyledCatDoor
      id="catDoor"
      width="179"
      height="389"
      xlinkHref={catDoorImage}
      transform="matrix(1 0 0 1 129 81)"
    />
  );
}

export default catDoor;
