import React from 'react';
import styled from 'styled-components';

const StyledPostureLogo = styled.svg`
  width: 100%;
  max-width: 150px;
  margin-bottom: 0.5em;
  padding: 0.2em;

  .st0 {
    fill: ${props => props.theme.colors.orange};
  }

  .st1 {
    fill: ${props => props.theme.colors.white};
  }

  ${props => props.theme.media.medium`
    margin: 0;
  `}
`;

const postureLogo = ( props ) => {
  return(
    <StyledPostureLogo viewBox="0 0 142.1 29.63">
      <g id="Group_172_1_" transform="translate(0 0)">
      	<circle id="Ellipse_32_1_" className="st0" cx="14.82" cy="14.82" r="14.82"/>
      	<circle id="Ellipse_33_1_" className="st1" cx="14.78" cy="14.82" r="14.29"/>
      	<circle id="Ellipse_34_1_" className="st0" cx="14.78" cy="14.82" r="13.76"/>

        <rect id="Rectangle_314_1_" x="1.08" y="11.69" transform="matrix(0.4428 -0.8966 0.8966 0.4428 -7.6501 16.1996)" className="st1" width="16.27" height="5.13"/>

        <rect id="Rectangle_315_1_" x="19" y="6.19" transform="matrix(1 -6.143520e-03 6.143520e-03 1 -0.0876 0.1328)" className="st1" width="5.13" height="16.27"/>
      </g>
      <path id="XMLID_12_" className="st1" d="M35.3,26.45h2.4V8.59h-2.4V6.43h4.77v2.16c1.24-1.54,3.11-2.44,5.09-2.44
      	c4,0,7.14,3.15,7.14,7.82c0,4.28-2.94,7.92-7.5,7.92c-1.72-0.01-3.38-0.62-4.7-1.73v6.33h2.94v2.12H35.3V26.45z M49.76,14
      	c0.03-3.43-2.09-5.66-4.95-5.66C43,8.39,41.3,9.21,40.14,10.61v7.5c1.27,1.1,2.89,1.7,4.56,1.7C47.67,19.69,49.72,17.29,49.76,14
      L49.76,14z"/>
      <path id="XMLID_11_" className="st1" d="M54.39,14.04c-0.02-4.32,3.47-7.83,7.78-7.85s7.83,3.47,7.85,7.78c0,0.02,0,0.04,0,0.06
      	c0.02,4.32-3.47,7.83-7.78,7.85s-7.83-3.47-7.85-7.78C54.39,14.08,54.39,14.06,54.39,14.04z M67.41,14.04
      c0-3.32-2.12-5.76-5.23-5.76s-5.23,2.37-5.23,5.76s2.12,5.76,5.23,5.76C65.28,19.73,67.41,17.4,67.41,14.04z"/>
      <path id="XMLID_10_" className="st1" d="M82.33,11.1c-0.86,0.04-1.6-0.62-1.66-1.49V9.51c0.01-0.29,0.07-0.58,0.18-0.85
      	c-0.82-0.41-1.74-0.59-2.65-0.53c-2.09,0-3.29,1.1-3.29,2.23c0,1.34,1.42,1.77,3.85,2.4c3.04,0.78,5.62,1.59,5.62,4.56
      	c0,2.9-2.83,4.56-6.05,4.56c-2.23,0-4.46-0.71-5.48-1.73c-0.52-0.48-0.82-1.16-0.81-1.87c-0.03-0.88,0.64-1.64,1.52-1.7
      	c0.85-0.07,1.59,0.56,1.66,1.41v0.14c-0.03,0.32-0.11,0.63-0.25,0.92c1.02,0.61,2.21,0.91,3.4,0.85c2.05,0,3.71-0.88,3.71-2.33
      	c0-1.41-1.42-1.94-3.57-2.44c-3.64-0.92-5.94-1.77-5.94-4.6c0-2.3,2.12-4.39,5.76-4.39c1.73-0.08,3.43,0.47,4.77,1.56
      c0.49,0.44,0.78,1.07,0.78,1.73C83.91,10.32,83.21,11.06,82.33,11.1z"/>
      <path id="XMLID_8_" className="st1" d="M88.69,20.08c-0.28-0.66-0.42-1.37-0.39-2.09V8.59h-2.83V6.43h2.83V2.76l2.4-0.95v4.63h4.67v2.16
      	H90.7v9.05c-0.01,0.4,0.08,0.8,0.25,1.17c0.28,0.53,0.85,0.78,1.77,0.78c1.01-0.09,1.99-0.4,2.86-0.92L95.27,21
      c-0.99,0.56-2.11,0.85-3.25,0.85C90.66,21.98,89.35,21.28,88.69,20.08z"/>
      <path id="XMLID_7_" className="st1" d="M99.73,19.87c-0.52-0.95-0.76-2.03-0.71-3.11V8.59h-2.4V6.43h4.88v9.87
      	c-0.04,0.7,0.12,1.4,0.46,2.02c0.5,0.9,1.49,1.4,2.51,1.27c1.87,0,3.57-1.2,4.99-2.65V8.59h-2.51V6.43h4.95v13.01h2.4v2.12h-4.77
      v-2.83c-1.35,1.8-3.41,2.93-5.66,3.11C101.78,21.85,100.47,21.14,99.73,19.87z"/>
      <path id="XMLID_6_" className="st1" d="M127.38,8.17c0.01,0.96-0.74,1.75-1.7,1.8c-0.81,0-1.53-0.51-1.8-1.27
      	c-1.45,0.62-2.62,1.77-3.25,3.22v7.6h2.86v2.12h-7.75v-2.12h2.4V8.63h-2.4V6.47h4.77v3.47c1.17-2.09,2.97-3.75,4.63-3.75
      c1.1-0.12,2.09,0.67,2.21,1.77C127.37,8.03,127.38,8.1,127.38,8.17z"/>
      <path id="XMLID_4_" className="st1" d="M130.28,14.71c0.35,3.32,2.58,4.99,5.83,4.99c2.08-0.03,4.11-0.61,5.91-1.66l-0.78,2.37
      	c-1.66,0.95-3.54,1.45-5.45,1.45c-4.81,0-8.1-3.22-8.1-7.85c0-4.17,2.83-7.92,7.43-7.92c4.49,0,6.97,3.4,6.97,7.14
      	c0.03,0.48-0.03,0.96-0.18,1.41h-11.7v0.07L130.28,14.71z M130.28,12.76h9.3c-0.18-2.65-1.7-4.49-4.6-4.49
      C132.47,8.28,130.41,10.26,130.28,12.76z"/>
    </StyledPostureLogo>
  );
}

export default postureLogo;
