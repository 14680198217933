import React from 'react';
import styled from 'styled-components';

import traditionalDoorImage from '../../../../../assets/images/traditional-house/traditional-door.png';


const StyledTraditionalDoor = styled.image`
  overflow: visible;
`;

const traditionalDoor = ( props ) => {
  return(
    <StyledTraditionalDoor
      id="traditionalDoor"
      width="180"
      height="393"
      xlinkHref={traditionalDoorImage}
      transform="matrix(1 0 0 1 128 81)"
    />
  );
}

export default traditionalDoor;
