import React from 'react';
import styled from 'styled-components';

import catRoofImage from '../../../../../assets/images/cat-house/cat-roof.png';

const StyledCatRoof = styled.image`
  overflow: visible;
`;

const catRoof = ( props ) => {
  return(
    <StyledCatRoof
      id="catRoof"
      width="431"
      height="278"
      xlinkHref={catRoofImage}
      transform="matrix(1 0 0 1 96 65)"
    />
  );
}

export default catRoof;
