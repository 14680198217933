import React from 'react';
import styled from 'styled-components';

import basicDoorImage from '../../../../../assets/images/basic-house/basic-door-thumbnail.png';

const StyledBasicDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const basicDoorThumbnail = ( props ) => {
  return(
    <StyledBasicDoorThumbnail
      id="basicDoorThumbnail"
      src={basicDoorImage}
    />
  );
}

export default basicDoorThumbnail;
