import React from 'react';
import styled from 'styled-components';

import dougBackground from '../../../../../assets/images/doug-house/doug-background-thumbnail.png';

const StyledDougLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dougLocationThumbnail = ( props ) => {
  return(
      <StyledDougLocationThumbnail
        id="dougLocationThumbnail"
        src={dougBackground}
      />
  );
}

export default dougLocationThumbnail;
