import React from 'react';
import styled from 'styled-components';

import crayolaWindowImage from '../../../../../assets/images/crayola-house/crayola-window.png';

const StyledCrayolaWindow = styled.image`
  overflow: visible;
`;

const crayolaWindow = ( props ) => {
  return(
    <StyledCrayolaWindow
      id="crayolaWindow"
      width="215"
      height="228"
      xlinkHref={crayolaWindowImage}
      transform="matrix(1 0 0 1 299 242)"
    />
  );
}

export default crayolaWindow;
