import React from 'react';
import styled from 'styled-components';

const StyledDoor = styled.svg`
  max-width: 17px;
  width: 100%;
  fill: ${props => props.theme.colors.darkBlue};
  transition: fill 0.3s;
`;

const door = ( props ) => {
  return(
    <StyledDoor viewBox="0 0 21.3 46.1">
      <path d="M3.5 11.1h5.6v14.2H3.5z"/>
      <path d="M12.2 28.3h5.6v14.2h-5.6z"/>
      <path d="M12.2 11.1h5.6v14.2h-5.6z"/>
      <path d="M3.5 3.5h5.6v4.8H3.5z"/>
      <path d="M0 0v46.1h21.3V0H0zm9.9 43.3H2.7V27.5h7.2v15.8zm0-17.2H2.7V10.4h7.2v15.7zM9.9 9H2.7V2.7h7.2V9zm8.7 34.3h-7.2V27.5h7.2v15.8zm0-17.2h-7.2V10.4h7.2v15.7zm0-17.1h-7.2V2.7h7.2V9z"/>
      <path d="M12.2 3.5h5.6v4.8h-5.6z"/>
      <path d="M3.5 28.3h5.6v14.2H3.5z"/>
    </StyledDoor>
  );
}

export default door;
