import React from 'react';
import styled from 'styled-components';

import dogRoofImage from '../../../../../assets/images/dog-house/doghouse-roof-thumbnail.png';

const StyledDogRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dogRoofThumbnail = ( props ) => {
  return(
      <StyledDogRoofThumbnail
        id="dogRoofThumbnail"
        src={dogRoofImage}
      />
  );
}

export default dogRoofThumbnail;
