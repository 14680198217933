/*
 * House Part Constants Object
 */

import locationIcon from '../../components/House/HouseControls/HouseIcons/Location';
import roofIcon from '../../components/House/HouseControls/HouseIcons/Roof';
import doorIcon from '../../components/House/HouseControls/HouseIcons/Door';
import windowIcon from '../../components/House/HouseControls/HouseIcons/Window';
import yardIcon from '../../components/House/HouseControls/HouseIcons/Yard';


// Importing all Door components here.
import BasicDoor from '../../components/House/HousePart/HouseParts/Doors/BasicDoor';
import BasicDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/BasicDoorThumbnail';
import CandyDoor from '../../components/House/HousePart/HouseParts/Doors/CandyDoor';
import CandyDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/CandyDoorThumbnail';
import DogDoor from '../../components/House/HousePart/HouseParts/Doors/DogDoor';
import DogDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/DogDoorThumbnail';
import NoreasterDoor from '../../components/House/HousePart/HouseParts/Doors/NoreasterDoor';
import NoreasterDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/NoreasterDoorThumbnail';
import TropicalDoor from '../../components/House/HousePart/HouseParts/Doors/TropicalDoor';
import TropicalDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/TropicalDoorThumbnail';
import TraditionalDoor from '../../components/House/HousePart/HouseParts/Doors/TraditionalDoor';
import TraditionalDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/TraditionalDoorThumbnail';
import WorkshopDoor from '../../components/House/HousePart/HouseParts/Doors/WorkshopDoor';
import WorkshopDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/WorkshopDoorThumbnail';
import CrayolaDoor from '../../components/House/HousePart/HouseParts/Doors/CrayolaDoor';
import CrayolaDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/CrayolaDoorThumbnail';
import CatDoor from '../../components/House/HousePart/HouseParts/Doors/CatDoor';
import CatDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/CatDoorThumbnail';
import PostureDoor from '../../components/House/HousePart/HouseParts/Doors/PostureDoor';
import PostureDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/PostureDoorThumbnail';
import HanukkahDoor from '../../components/House/HousePart/HouseParts/Doors/HanukkahDoor';
import HanukkahDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/HanukkahDoorThumbnail';
import DougDoor from '../../components/House/HousePart/HouseParts/Doors/DougDoor';
import DougDoorThumbnail from '../../components/House/HousePart/HouseParts/Doors/DougDoorThumbnail';

// Importing all Window components here.
import BasicWindow from '../../components/House/HousePart/HouseParts/Windows/BasicWindow';
import BasicWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/BasicWindowThumbnail';
import CandyWindow from '../../components/House/HousePart/HouseParts/Windows/CandyWindow';
import CandyWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/CandyWindowThumbnail';
import DogWindow from '../../components/House/HousePart/HouseParts/Windows/DogWindow';
import DogWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/DogWindowThumbnail';
import NoreasterWindow from '../../components/House/HousePart/HouseParts/Windows/NoreasterWindow';
import NoreasterWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/NoreasterWindowThumbnail';
import TropicalWindow from '../../components/House/HousePart/HouseParts/Windows/TropicalWindow';
import TropicalWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/TropicalWindowThumbnail';
import TraditionalWindow from '../../components/House/HousePart/HouseParts/Windows/TraditionalWindow';
import TraditionalWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/TraditionalWindowThumbnail';
import WorkshopWindow from '../../components/House/HousePart/HouseParts/Windows/WorkshopWindow';
import WorkshopWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/WorkshopWindowThumbnail';
import CrayolaWindow from '../../components/House/HousePart/HouseParts/Windows/CrayolaWindow';
import CrayolaWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/CrayolaWindowThumbnail';
import CatWindow from '../../components/House/HousePart/HouseParts/Windows/CatWindow';
import CatWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/CatWindowThumbnail';
import PostureWindow from '../../components/House/HousePart/HouseParts/Windows/PostureWindow';
import PostureWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/PostureWindowThumbnail';
import HanukkahWindow from '../../components/House/HousePart/HouseParts/Windows/HanukkahWindow';
import HanukkahWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/HanukkahWindowThumbnail';
import DougWindow from '../../components/House/HousePart/HouseParts/Windows/DougWindow';
import DougWindowThumbnail from '../../components/House/HousePart/HouseParts/Windows/DougWindowThumbnail';

// Import all Roof components here.
import BasicRoof from '../../components/House/HousePart/HouseParts/Roofs/BasicRoof';
import BasicRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/BasicRoofThumbnail';
import CandyRoof from '../../components/House/HousePart/HouseParts/Roofs/CandyRoof';
import CandyRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/CandyRoofThumbnail';
import DogRoof from '../../components/House/HousePart/HouseParts/Roofs/DogRoof';
import DogRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/DogRoofThumbnail';
import NoreasterRoof from '../../components/House/HousePart/HouseParts/Roofs/NoreasterRoof';
import NoreasterRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/NoreasterRoofThumbnail';
import TropicalRoof from '../../components/House/HousePart/HouseParts/Roofs/TropicalRoof';
import TropicalRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/TropicalRoofThumbnail';
import TraditionalRoof from '../../components/House/HousePart/HouseParts/Roofs/TraditionalRoof';
import TraditionalRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/TraditionalRoofThumbnail';
import WorkshopRoof from '../../components/House/HousePart/HouseParts/Roofs/WorkshopRoof';
import WorkshopRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/WorkshopRoofThumbnail';
import CrayolaRoof from '../../components/House/HousePart/HouseParts/Roofs/CrayolaRoof';
import CrayolaRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/CrayolaRoofThumbnail';
import CatRoof from '../../components/House/HousePart/HouseParts/Roofs/CatRoof';
import CatRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/CatRoofThumbnail';
import PostureRoof from '../../components/House/HousePart/HouseParts/Roofs/PostureRoof';
import PostureRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/PostureRoofThumbnail';
import HanukkahRoof from '../../components/House/HousePart/HouseParts/Roofs/HanukkahRoof';
import HanukkahRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/HanukkahRoofThumbnail';
import DougRoof from '../../components/House/HousePart/HouseParts/Roofs/DougRoof';
import DougRoofThumbnail from '../../components/House/HousePart/HouseParts/Roofs/DougRoofThumbnail';

// Import all Yard components here.
import BasicYard from '../../components/House/HousePart/HouseParts/Yards/BasicYard';
import BasicYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/BasicYardThumbnail';
import CandyYard from '../../components/House/HousePart/HouseParts/Yards/CandyYard';
import CandyYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/CandyYardThumbnail';
import DogYard from '../../components/House/HousePart/HouseParts/Yards/DogYard';
import DogYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/DogYardThumbnail';
import NoreasterYard from '../../components/House/HousePart/HouseParts/Yards/NoreasterYard';
import NoreasterYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/NoreasterYardThumbnail';
import TropicalYard from '../../components/House/HousePart/HouseParts/Yards/TropicalYard';
import TropicalYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/TropicalYardThumbnail';
import TraditionalYard from '../../components/House/HousePart/HouseParts/Yards/TraditionalYard';
import TraditionalYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/TraditionalYardThumbnail';
import WorkshopYard from '../../components/House/HousePart/HouseParts/Yards/WorkshopYard';
import WorkshopYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/WorkshopYardThumbnail';
import CrayolaYard from '../../components/House/HousePart/HouseParts/Yards/CrayolaYard';
import CrayolaYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/CrayolaYardThumbnail';
import CatYard from '../../components/House/HousePart/HouseParts/Yards/CatYard';
import CatYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/CatYardThumbnail';
import PostureYard from '../../components/House/HousePart/HouseParts/Yards/PostureYard';
import PostureYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/PostureYardThumbnail';
import HanukkahYard from '../../components/House/HousePart/HouseParts/Yards/HanukkahYard';
import HanukkahYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/HanukkahYardThumbnail';
import DougYard from '../../components/House/HousePart/HouseParts/Yards/DougYard';
import DougYardThumbnail from '../../components/House/HousePart/HouseParts/Yards/DougYardThumbnail';


// Import all Location components here.
import BasicLocation from '../../components/House/HousePart/HouseParts/Locations/BasicLocation';
import BasicLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/BasicLocationThumbnail';
import CandyLocation from '../../components/House/HousePart/HouseParts/Locations/CandyLocation';
import CandyLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/CandyLocationThumbnail';
import DogLocation from '../../components/House/HousePart/HouseParts/Locations/DogLocation';
import DogLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/DogLocationThumbnail';
import NoreasterLocation from '../../components/House/HousePart/HouseParts/Locations/NoreasterLocation';
import NoreasterLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/NoreasterLocationThumbnail';
import TropicalLocation from '../../components/House/HousePart/HouseParts/Locations/TropicalLocation';
import TropicalLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/TropicalLocationThumbnail';
import TraditionalLocation from '../../components/House/HousePart/HouseParts/Locations/TraditionalLocation';
import TraditionalLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/TraditionalLocationThumbnail';
import WorkshopLocation from '../../components/House/HousePart/HouseParts/Locations/WorkshopLocation';
import WorkshopLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/WorkshopLocationThumbnail';
import CrayolaLocation from '../../components/House/HousePart/HouseParts/Locations/CrayolaLocation';
import CrayolaLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/CrayolaLocationThumbnail';
import CatLocation from '../../components/House/HousePart/HouseParts/Locations/CatLocation';
import CatLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/CatLocationThumbnail';
import PostureLocation from '../../components/House/HousePart/HouseParts/Locations/PostureLocation';
import PostureLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/PostureLocationThumbnail';
import HanukkahLocation from '../../components/House/HousePart/HouseParts/Locations/HanukkahLocation';
import HanukkahLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/HanukkahLocationThumbnail';
import DougLocation from '../../components/House/HousePart/HouseParts/Locations/DougLocation';
import DougLocationThumbnail from '../../components/House/HousePart/HouseParts/Locations/DougLocationThumbnail';


// Import all Foundation Components here.
import JanetWeisFoundation from '../../components/House/HousePart/HouseParts/Foundations/JanetWeisFoundation';
import janetWeisFoundationImage from '../../assets/images/foundation/janetweis-foundation.png';
import UnitedWayFoundation from '../../components/House/HousePart/HouseParts/Foundations/UnitedWayFoundation';
import unitedWayFoundationImage from '../../assets/images/foundation/unitedway-foundation.png';
import PoconoWatersFoundation from '../../components/House/HousePart/HouseParts/Foundations/PoconoWatersFoundation';
import poconowatersFoundationImage from '../../assets/images/foundation/poconowaters-foundation.png';
import AieFoundation from '../../components/House/HousePart/HouseParts/Foundations/AieFoundation';
import aieFoundationImage from '../../assets/images/foundation/aie-foundation.png';
import BluechipFoundation from '../../components/House/HousePart/HouseParts/Foundations/BluechipFoundation';
import bluechipFoundationImage from '../../assets/images/foundation/bluechip-foundation.png';


const HOUSE_PARTS = [
  {
    id: 1,
    part: 'location',
    icon: locationIcon,
    parts: {
      'basic-location': {
        part: BasicLocation,
        thumbnail: BasicLocationThumbnail,
      },
      'candy-location': {
        part: CandyLocation,
        thumbnail: CandyLocationThumbnail,
      },
      'dog-location': {
        part: DogLocation,
        thumbnail: DogLocationThumbnail,
      },
      'noreaster-location': {
        part: NoreasterLocation,
        thumbnail: NoreasterLocationThumbnail,
      },
      'tropical-location': {
        part: TropicalLocation,
        thumbnail: TropicalLocationThumbnail,
      },
      'traditional-location': {
        part: TraditionalLocation,
        thumbnail: TraditionalLocationThumbnail,
      },
      'workshop-location': {
        part: WorkshopLocation,
        thumbnail: WorkshopLocationThumbnail,
      },
      'crayola-location': {
        part: CrayolaLocation,
        thumbnail: CrayolaLocationThumbnail,
      },
      'cat-location': {
        part: CatLocation,
        thumbnail: CatLocationThumbnail,
      },
      'posture-location': {
        part: PostureLocation,
        thumbnail: PostureLocationThumbnail,
      },
      'hanukkah-location': {
        part: HanukkahLocation,
        thumbnail: HanukkahLocationThumbnail,
      },
      'doug-location': {
        part: DougLocation,
        thumbnail: DougLocationThumbnail,
      }
    },
  },
  {
    id: 2,
    part: 'roof',
    icon: roofIcon,
    parts: {
      'basic-roof': {
        part: BasicRoof,
        thumbnail: BasicRoofThumbnail,
      },
      'candy-roof': {
        part: CandyRoof,
        thumbnail: CandyRoofThumbnail,
      },
      'dog-roof': {
        part: DogRoof,
        thumbnail: DogRoofThumbnail,
      },
      'noreaster-roof': {
        part: NoreasterRoof,
        thumbnail: NoreasterRoofThumbnail,
      },
      'tropical-roof': {
        part: TropicalRoof,
        thumbnail: TropicalRoofThumbnail,
      },
      'traditional-roof': {
        part: TraditionalRoof,
        thumbnail: TraditionalRoofThumbnail,
      },
      'workshop-roof': {
        part: WorkshopRoof,
        thumbnail: WorkshopRoofThumbnail,
      },
      'crayola-roof': {
        part: CrayolaRoof,
        thumbnail: CrayolaRoofThumbnail,
      },
      'cat-roof': {
        part: CatRoof,
        thumbnail: CatRoofThumbnail,
      },
      'posture-roof': {
        part: PostureRoof,
        thumbnail: PostureRoofThumbnail,
      },
      'hanukkah-roof': {
        part: HanukkahRoof,
        thumbnail: HanukkahRoofThumbnail,
      },
      'doug-roof': {
        part: DougRoof,
        thumbnail: DougRoofThumbnail,
      }
    },
  },
  {
    id: 3,
    part: 'door',
    icon: doorIcon,
    parts: {
      'basic-door': {
        part: BasicDoor,
        thumbnail: BasicDoorThumbnail,
      },
      'candy-door': {
        part: CandyDoor,
        thumbnail: CandyDoorThumbnail,
      },
      'dog-door': {
        part: DogDoor,
        thumbnail: DogDoorThumbnail,
      },
      'noreaster-door': {
        part: NoreasterDoor,
        thumbnail: NoreasterDoorThumbnail,
      },
      'tropical-door': {
        part: TropicalDoor,
        thumbnail: TropicalDoorThumbnail,
      },
      'traditional-door': {
        part: TraditionalDoor,
        thumbnail: TraditionalDoorThumbnail,
      },
      'workshop-door': {
        part: WorkshopDoor,
        thumbnail: WorkshopDoorThumbnail,
      },
      'crayola-door': {
        part: CrayolaDoor,
        thumbnail: CrayolaDoorThumbnail,
      },
      'cat-door': {
        part: CatDoor,
        thumbnail: CatDoorThumbnail,
      },
      'posture-door': {
        part: PostureDoor,
        thumbnail: PostureDoorThumbnail,
      },
      'hanukkah-door': {
        part: HanukkahDoor,
        thumbnail: HanukkahDoorThumbnail,
      },
      'doug-door': {
        part: DougDoor,
        thumbnail: DougDoorThumbnail,
      }
    },
  },
  {
    id: 4,
    part: 'window',
    icon: windowIcon,
    parts: {
      'basic-window': {
        part: BasicWindow,
        thumbnail: BasicWindowThumbnail,
      },
      'candy-window': {
        part: CandyWindow,
        thumbnail: CandyWindowThumbnail,
      },
      'dog-window': {
        part: DogWindow,
        thumbnail: DogWindowThumbnail,
      },
      'noreaster-window': {
        part: NoreasterWindow,
        thumbnail: NoreasterWindowThumbnail,
      },
      'tropical-window': {
        part: TropicalWindow,
        thumbnail: TropicalWindowThumbnail,
      },
      'traditional-window': {
        part: TraditionalWindow,
        thumbnail: TraditionalWindowThumbnail,
      },
      'workshop-window': {
        part: WorkshopWindow,
        thumbnail: WorkshopWindowThumbnail,
      },
      'crayola-window': {
        part: CrayolaWindow,
        thumbnail: CrayolaWindowThumbnail,
      },
      'cat-window': {
        part: CatWindow,
        thumbnail: CatWindowThumbnail,
      },
      'posture-window': {
        part: PostureWindow,
        thumbnail: PostureWindowThumbnail,
      },
      'hanukkah-window': {
        part: HanukkahWindow,
        thumbnail: HanukkahWindowThumbnail,
      },
      'doug-window': {
        part: DougWindow,
        thumbnail: DougWindowThumbnail,
      }
    },
  },
  {
    id: 5,
    part: 'yard',
    icon: yardIcon,
    parts: {
      'basic-yard': {
        part: BasicYard,
        thumbnail: BasicYardThumbnail,
      },
      'candy-yard': {
        part: CandyYard,
        thumbnail: CandyYardThumbnail,
      },
      'dog-yard': {
        part: DogYard,
        thumbnail: DogYardThumbnail,
      },
      'noreaster-yard': {
        part: NoreasterYard,
        thumbnail: NoreasterYardThumbnail,
      },
      'tropical-yard': {
        part: TropicalYard,
        thumbnail: TropicalYardThumbnail,
      },
      'traditional-yard': {
        part: TraditionalYard,
        thumbnail: TraditionalYardThumbnail,
      },
      'workshop-yard': {
        part: WorkshopYard,
        thumbnail: WorkshopYardThumbnail,
      },
      'crayola-yard': {
        part: CrayolaYard,
        thumbnail: CrayolaYardThumbnail,
      },
      'cat-yard': {
        part: CatYard,
        thumbnail: CatYardThumbnail,
      },
      'posture-yard': {
        part: PostureYard,
        thumbnail: PostureYardThumbnail,
      },
      'hanukkah-yard': {
        part: HanukkahYard,
        thumbnail: HanukkahYardThumbnail,
      },
      'doug-yard': {
        part: DougYard,
        thumbnail: DougYardThumbnail,
      }
    },
  },
  {
    id: 6,
    part: 'foundation',
    icon: '',
    parts: {
      'unitedway-foundation': {
        part: UnitedWayFoundation,
        image: unitedWayFoundationImage,
        label: 'united way',
      },
      'janetweis-foundation': {
        part: JanetWeisFoundation,
        image: janetWeisFoundationImage,
        label: 'Janet Weis Children\'s Hospital',
      },
      'poconowaters-foundation': {
        part: PoconoWatersFoundation,
        image: poconowatersFoundationImage,
        label: 'Our Pocono Waters',
      },
      'aie-foundation': {
        part: AieFoundation,
        image: aieFoundationImage,
        label: 'Arts In Education',
      },
      'bluechip-foundation': {
        part: BluechipFoundation,
        image: bluechipFoundationImage,
        label: 'Blue Chip Farm',
      }
    },
  },
];

export default HOUSE_PARTS;
