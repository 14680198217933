import React from 'react';
import styled from 'styled-components';

import hanukkahDoorImage from '../../../../../assets/images/hanukkah-house/hanukkah-door.png';


const StyledHanukkahDoor = styled.image`
  overflow: visible;
`;

const hanukkahDoor = ( props ) => {
  return(
    <StyledHanukkahDoor
      id="hanukkahDoor"
      width="223"
      height="388"
      xlinkHref={hanukkahDoorImage}
      transform="matrix(1 0 0 1 114 81)"
    />
  );
}

export default hanukkahDoor;
