import React from 'react';
import styled from 'styled-components';

import crayolaBackground from '../../../../../assets/images/crayola-house/crayola-background-thumbnail.png';

const StyledCrayolaLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const crayolaLocationThumbnail = ( props ) => {
  return(
      <StyledCrayolaLocationThumbnail
        id="crayolaLocationThumbnail"
        src={crayolaBackground}
      />
  );
}

export default crayolaLocationThumbnail;
