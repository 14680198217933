import React from 'react';
import styled from 'styled-components';

import candyDoorImage from '../../../../../assets/images/candyland-house/candyland-door-thumbnail.png';

const StyledCandyDoorThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const candyDoorThumbnail = ( props ) => {
  return(
    <StyledCandyDoorThumbnail
      id="candyDoorThumbnail"
      src={candyDoorImage}
    />
  );
}

export default candyDoorThumbnail;
