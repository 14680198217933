import React from 'react';
import styled from 'styled-components';

import workshopRoofImage from '../../../../../assets/images/workshop-house/workshop-roof-thumbnail.png';

const StyledWorkshopRoofThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const workshopRoofThumbnail = ( props ) => {
  return(
      <StyledWorkshopRoofThumbnail
        id="workshopRoofThumbnail"
        src={workshopRoofImage}
      />
  );
}

export default workshopRoofThumbnail;
