/*
 * Welcome Stateful Component
 * This Component controls the welcome page and passes it's state down to the
 * components rendered on the page.
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import PostureHousePartyLogo from '../../components/Logo/PostureHousePartyLogo';
import CandyCane from '../../components/CandyCane/CandyCane';
import Video from '../../components/Video/Video';
import Button from '../../components/UI/Button/Button';

const StyledWelcome = styled.section`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  display: flex;
  flex-flow: column;
  max-width: 75rem;
  margin: 0 auto;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;

  ${props => props.theme.media.medium`
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  `}

  ${props => props.theme.media.large`
    overflow: hidden;
  `}
`;

const StyledWelcomeLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 0.5em;
  box-sizing: border-box;
  height: 20%;
  z-index: 2;

  ${props => props.theme.orientation.landscape`
    display: block;
    height: 100%;
    max-width: 80%;
    margin: 0 auto;
  `}

  ${props => props.theme.media.largeSmall`
    height: 70%;
  `}

  ${props => props.theme.media.medium`
    display: flex;
    flex-flow: row;
    max-width: 90%;
    height: auto;
  `}

  ${props => props.theme.media.large`
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: min-content;
    max-width: calc(40% - 1em);
    margin-right: auto;
    margin-bottom: 5em;
    margin-left: auto;
  `}
`;

const StyledVideoWrapper = styled.div`
  z-index: 2;

  ${props => props.theme.media.largeSmall`
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 60%;
  `}


  ${props => props.theme.media.medium`
    flex: 1 0 80%;
    padding: 1em;
  `}

  ${props => props.theme.media.large`
    height: min-content;
    max-width: calc(40% - 1em);
    margin-right: 1em;
    margin-bottom: 5em;
    padding: 0;
    display: block;
  `}
`;

const StyledCandyCaneWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 100%;
  top: 0;
  text-align: right;
  height: 100%;
  max-height: 29%;
  overflow: hidden;


  ${props => props.theme.media.largeSmall`
    max-height: 90%;
  `}

  ${props => props.theme.media.medium`
    right: 0;
    z-index: -1;
    overflow: hidden;
    height: 100%;
    top: 0;
    max-height: 380px;
  `}

  ${props => props.theme.media.large`
    left: 10%;
    width: 50%;
    z-index: -1;
    overflow: hidden;
    height: 40%;
    top: 68%;
    margin-left: 5em;
    max-height: 310px;
    text-align: left;
  `}
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
`;

const StyledLogoTabletWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;

  button {
    margin: 1em 0;
  }
`;

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTablet: false,
      isDesktop: false,
      videoURL: 'https://player.vimeo.com/external/304275993.hd.mp4?s=5d2e81bdcd17e0dcf82be9e36ed8f9f2e9e11926&profile_id=175',
    };
  }

  componentDidMount() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeHandler = () => {
    this.setState({
      isTablet: window.innerWidth >= 768,
      isDesktop: window.innerWidth >= 1024,
    });
  }

  buildClickHandler = () => {
    this.props.history.push('/build');
  }

  render() {
    const isDesktop = this.state.isDesktop;
    const isTablet = this.state.isTablet;

    let content = (
      <React.Fragment>
        <StyledWelcomeLogoWrapper>
          <PostureHousePartyLogo />
          <StyledCandyCaneWrapper>
            <CandyCane />
          </StyledCandyCaneWrapper>
        </StyledWelcomeLogoWrapper>
        <StyledVideoWrapper>
          <StyledButtonWrapper>
            <Button clicked={this.buildClickHandler}>build your house</Button>
          </StyledButtonWrapper>
          <Video videoSrc={this.state.videoURL}/>
        </StyledVideoWrapper>

      </React.Fragment>
    );

    if (isDesktop) {
      content = (
        <React.Fragment>
          <StyledWelcomeLogoWrapper>
            <PostureHousePartyLogo />
            <Button clicked={this.buildClickHandler}>build your house</Button>
          </StyledWelcomeLogoWrapper>
          <StyledVideoWrapper>
            <Video videoSrc={this.state.videoURL}/>
          </StyledVideoWrapper>
          <StyledCandyCaneWrapper>
            <CandyCane />
          </StyledCandyCaneWrapper>
        </React.Fragment>
      );
    } else if (isTablet) {
      content = (
        <React.Fragment>
          <StyledWelcomeLogoWrapper>
            <StyledLogoTabletWrapper>
              <PostureHousePartyLogo />
              <Button clicked={this.buildClickHandler}>build your house</Button>
            </StyledLogoTabletWrapper>
            <StyledCandyCaneWrapper>
              <CandyCane />
            </StyledCandyCaneWrapper>
          </StyledWelcomeLogoWrapper>
          <StyledVideoWrapper>
            <Video videoSrc={this.state.videoURL}/>
          </StyledVideoWrapper>
        </React.Fragment>
      );
    }
    return(
      <StyledWelcome>
        <Helmet>
          <title>Welcome to Posture House Builder</title>
        </Helmet>
        {content}
      </StyledWelcome>
    );
  }
}

export default Welcome;
