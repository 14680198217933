import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import HouseCarouselItem from './HouseCarouselItem';
import SliderArrowNext from '../../Arrow/SliderArrowNext';
import SliderArrowPrev from '../../Arrow/SliderArrowPrev';

const StyledHouseCarousel = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.lighterBlue};
  height: 90px;
  display: block;
  position: relative;

  .slick-slider {
    width: 100%;
    height: 100%;
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-prev,
  .slick-next {
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => props.theme.media.large`
      display: block;
    `}
  }

  .slick-prev {
    left: -45px;
  }

  .slick-next {
    right: -45px;
  }

  .slick-prev::before,
  .slick-next::before {
    display: none;
    content: none;
  }
`;

const StyledPrevArrow = styled.div`
  display: block;

  &:hover svg,
  &:focus svg {
    fill: ${props => props.theme.colors.orange};
  }

  &:active svg {
    fill: ${props => props.theme.colors.orangeHover};
  }

  &.slick-disabled {
    cursor: not-allowed;
  }

  &.slick-disabled svg {
    fill: ${props => props.theme.colors.darkBlueDisabled};
  }
`;

const StyledNextArrow = styled.div`
  display: block;

  &:hover svg,
  &:focus svg {
    fill: ${props => props.theme.colors.orange};
  }

  &:active svg {
    fill: ${props => props.theme.colors.orangeHover};
  }

  &.slick-disabled {
    cursor: not-allowed;
  }

  &.slick-disabled svg {
    fill: ${props => props.theme.colors.darkBlueDisabled};
  }
`;


const NextArrow = ( props ) => {
  const { className, onClick } = props;
  return(
    <StyledNextArrow
      className={className}
      onClick={onClick} >
      <SliderArrowNext />
    </StyledNextArrow>
  );
}

const PrevArrow = ( props ) => {
  const { className, onClick } = props;
  return(
    <StyledPrevArrow
      className={className}
      onClick={onClick} >
      <SliderArrowPrev />
    </StyledPrevArrow>
  );
};

const houseCarousel = ( props ) => {
  const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
  };

  const passedItems = Object.values(props.carouselItems) || [];
  const itemObj = Object.entries(props.carouselItems);

  return(
    <StyledHouseCarousel>
      <Slider {...settings}>
        {passedItems.map((item, index) => (
          <HouseCarouselItem
            key={index}
            clickedPart={() => props.clickedPart(itemObj[index][0])}
          >
            <item.thumbnail />
          </HouseCarouselItem>
        ))}
      </Slider>
    </StyledHouseCarousel>
  );
}

export default houseCarousel;
