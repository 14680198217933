import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BasicRoof from './HouseParts/Roofs/BasicRoof';
import CandyRoof from './HouseParts/Roofs/CandyRoof';
import DogRoof from './HouseParts/Roofs/DogRoof';
import NoreasterRoof from './HouseParts/Roofs/NoreasterRoof';
import TropicalRoof from './HouseParts/Roofs/TropicalRoof';
import TraditionalRoof from './HouseParts/Roofs/TraditionalRoof';
import WorkshopRoof from './HouseParts/Roofs/WorkshopRoof';
import CrayolaRoof from './HouseParts/Roofs/CrayolaRoof';
import CatRoof from './HouseParts/Roofs/CatRoof';
import PostureRoof from './HouseParts/Roofs/PostureRoof';
import HanukkahRoof from './HouseParts/Roofs/HanukkahRoof';
import DougRoof from './HouseParts/Roofs/DougRoof';

class HousePartRoof extends Component {
  render() {
    let part = null;

    switch(this.props.type) {
      case ('basic-roof'):
        part = <BasicRoof />;
        break;
      case ('candy-roof'):
        part = <CandyRoof />;
        break;
      case ('dog-roof'):
        part = <DogRoof />;
        break;
      case ('noreaster-roof'):
        part = <NoreasterRoof />;
        break;
      case ('tropical-roof'):
        part = <TropicalRoof />;
        break;
      case ('traditional-roof'):
        part = <TraditionalRoof />;
        break;
      case ('workshop-roof'):
        part = <WorkshopRoof />;
        break;
      case ('crayola-roof'):
        part = <CrayolaRoof />;
        break;
      case ('cat-roof'):
        part = <CatRoof />;
        break;
      case ('posture-roof'):
        part = <PostureRoof />;
        break;
      case ('hanukkah-roof'):
        part = <HanukkahRoof />;
        break;
      case ('doug-roof'):
        part = <DougRoof />;
        break;
      default:
        part = null;
        }
    return part;
  }
}

HousePartRoof.propTypes = {
  type: PropTypes.string.isRequired,
}

export default HousePartRoof;
