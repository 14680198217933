import React from 'react';
import styled from 'styled-components';

import FoundationItem from './FoundationItem';

const StyledFoundationItems = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

const foundationItems = ( props ) => {
  const passedItems = Object.values(props.items) || [];
  const itemObj = Object.entries(props.items);
  const itemKeys = Object.keys(props.items);
  return(
    <StyledFoundationItems>
      {passedItems.map((item, index) => (
        <FoundationItem
          key={index}
          clickedItem={() => props.clickedItem(itemObj[index][0])}
          activeItem={(props.activeItem === itemKeys[index]) ? props.activeItem : null}>
          <img src={item.image} alt="Foundation Logos" />
          <p>{item.label}</p>
        </FoundationItem>
      ))}

    </StyledFoundationItems>
  );
}

export default foundationItems;
