import React from 'react';
import styled from 'styled-components';

import dogYardImage from '../../../../../assets/images/dog-house/doghouse-yard-thumbnail.png';


const StyledDogYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const dogYardThumbnail = ( props ) => {
  return(
      <StyledDogYardThumbnail
        id="dogYardThumbnail"
        src={dogYardImage}
      />
  );
}

export default dogYardThumbnail;
