/*
 * Index for App 
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import 'normalize.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

/* Importing fonts from Typekit */
const WebFontConfig = {
    typekit: { id: 'bbn5cek' }
 };


const app = (
  <WebfontLoader config={WebFontConfig}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </WebfontLoader>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
