import React from 'react';
import styled from 'styled-components';

import candyBackground from '../../../../../assets/images/candyland-house/candyland-background.png';

const StyledCandyLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const candyLocation = ( props ) => {
  return(
    <StyledCandyLocation
      id="candyLocation"
      width="600"
      height="600"
      xlinkHref={candyBackground}
    />
  );
}

export default candyLocation;
