import React from 'react';
import styled from 'styled-components';

import traditionalYardImage from '../../../../../assets/images/traditional-house/traditional-yard-thumbnail.png';


const StyledTraditionalYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const traditionalYardThumbnail = ( props ) => {
  return(
      <StyledTraditionalYardThumbnail
        id="traditionalYardThumbnail"
        src={traditionalYardImage}
      />
  );
}

export default traditionalYardThumbnail;
