import React from 'react';
import styled from 'styled-components';


const StyledHouseImage = styled.img`
  width: 100%;
  opacity: 0;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.5s;
`;

const houseImage = ( props ) =>  {
  return(
    <StyledHouseImage
      src={props.src}
      alt={props.alt}
      onLoad={props.load}
      show={props.show}
      className={props.className}/>
  );
}

export default houseImage;
