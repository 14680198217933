import React from 'react';
import styled from 'styled-components';

import traditionalBackground from '../../../../../assets/images/traditional-house/traditional-background.png';

const StyledTraditionalLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const traditionalLocation = ( props ) => {
  return(
    <StyledTraditionalLocation
      id="traditionalLocation"
      width="600"
      height="600"
      xlinkHref={traditionalBackground}
    />
  );
}

export default traditionalLocation;
