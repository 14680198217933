import React from 'react';
import styled from 'styled-components';

import traditionalYardImage from '../../../../../assets/images/traditional-house/traditional-yard.png';

const StyledTraditionalYard = styled.image`
    overflow: visible;
`;

const traditionalYard = ( props ) => {
  return(
    <StyledTraditionalYard
      id="traditionalYard"
      width="600"
      height="288"
      xlinkHref={traditionalYardImage}
      transform="matrix(1 0 0 1 0 312)"
    />
  );
}

export default traditionalYard;
