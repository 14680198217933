import React from 'react';
import styled from 'styled-components';

import candyYardImage from '../../../../../assets/images/candyland-house/candyland-yard-thumbnail.png';


const StyledCandyYardThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const candyYardThumbnail = ( props ) => {
  return(
      <StyledCandyYardThumbnail
        id="candyYardThumbnail"
        src={candyYardImage}
      />
  );
}

export default candyYardThumbnail;
