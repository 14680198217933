import React from 'react';
import styled from 'styled-components';

import noreasterWindowImage from '../../../../../assets/images/noreaster-house/noreaster-window-thumbnail.png';

const StyledNoreasterWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const noreasterWindowThumbnail = ( props ) => {
  return(
      <StyledNoreasterWindowThumbnail
        id="noreasterWindow"
        src={noreasterWindowImage}
      />
  );
}

export default noreasterWindowThumbnail;
