import React from 'react';
import styled from 'styled-components';

import traditionalWindowImage from '../../../../../assets/images/traditional-house/traditional-roof-thumbnail.png';

const StyledTraditionalWindowThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const traditionalWindowThumbnail = ( props ) => {
  return(
      <StyledTraditionalWindowThumbnail
        id="traditionalWindow"
        src={traditionalWindowImage}
      />
  );
}

export default traditionalWindowThumbnail;
