import React from 'react';
import styled from 'styled-components';

import workshopBackground from '../../../../../assets/images/workshop-house/workshop-background.png';

const StyledWorkshopLocation = styled.image`
  overflow: visible;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;
`;

const workshopLocation = ( props ) => {
  return(
    <StyledWorkshopLocation
      id="workshopLocation"
      width="600"
      height="600"
      xlinkHref={workshopBackground}
    />
  );
}

export default workshopLocation;
