import React from 'react';
import styled from 'styled-components';


const StyledHouseControl = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0;

  p {
    color: ${props => props.activeItem ? props.theme.colors.orange : props.theme.colors.darkBlue};
  }

  button {
    border: 1px solid ${props => props.activeItem ? props.theme.colors.orange : props.theme.colors.darkBlue};
  }

  svg {
    fill: ${props => props.activeItem ? props.theme.colors.orange : props.theme.colors.darkBlue};
  }

  &:hover,
  &:focus {
    p {
      color: ${props => props.activeItem ? props.theme.colors.orange : props.theme.colors.darkBlueHover};
    }

    button {
      border-color: ${props => props.activeItem ? props.theme.colors.orange : props.theme.colors.darkBlueHover};

      svg {
        fill: ${props => props.activeItem ? props.theme.colors.orange : props.theme.colors.darkBlueHover};
      }
    }
  }

  &:active {
    p {
      color: ${props => props.theme.colors.darkBlueActive};
    }

    button {
      border-color: ${props => props.theme.colors.darkBlueActive};

      svg {
        fill: ${props => props.theme.colors.darkBlueActive};
      }
    }
  }
`;

const StyledHouseLabel = styled.p`
  font-size: 0.8em;
  font-weight: bold;
  margin: 0.5em 0;
  text-transform: capitalize;
  transition: color 0.3s;
`;

const StyledHouseButton = styled.button`
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.darkBlue};
  background-color: ${props => props.theme.colors.white};
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s;
`;

const houseControl = ( props ) => {
  return(
    <StyledHouseControl
      onClick={props.clickedItem}
      activeItem={props.activeItem}>
      <StyledHouseButton >
        {props.children}
      </StyledHouseButton>
      <StyledHouseLabel>
        {props.label}
      </StyledHouseLabel>
    </StyledHouseControl>
  );
}

export default houseControl;
