import React from 'react';
import styled from 'styled-components';

import candyWindowImage from '../../../../../assets/images/candyland-house/candyland-window.png';

const StyledCandyWindow = styled.image`
  overflow: visible;
`;

const candyWindow = ( props ) => {
  return(
    <StyledCandyWindow
      id="candyWindow"
      width="209"
      height="222"
      xlinkHref={candyWindowImage}
      transform="matrix(1 0 0 1 303 246)"
    />
  );
}

export default candyWindow;
