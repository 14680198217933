import React from 'react';
import styled from 'styled-components';

import noreasterDoorImage from '../../../../../assets/images/noreaster-house/noreaster-door.png';


const StyledNoreasterDoor = styled.image`
  overflow: visible;
`;

const noreasterDoor = ( props ) => {
  return(
    <StyledNoreasterDoor
      id="noreasterDoor"
      width="180"
      height="389"
      xlinkHref={noreasterDoorImage}
      transform="matrix(1 0 0 1 129 81)"
    />
  );
}

export default noreasterDoor;
