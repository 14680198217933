/*
 * Share Stateful Component
 * This Component controls the share page and passes it's state down to the
 * components rendered on the page.
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import download from 'downloadjs';
import yourls from 'yourls-api';
import { detect } from 'detect-browser';

import SHARE_SOCIALS from '../../data/constants/SHARE_SOCIALS';
import NAV_MENU from '../../data/constants/NAV_MENU';
import HousePartyLogoHorizontal from '../../components/Logo/HousePartyLogoHorizontal';
import HousePartyLogo from '../../components/Logo/HousePartyLogo';
import CandyCane from '../../components/CandyCane/CandyCane';
import Button from '../../components/UI/Button/Button';
import ShareItems from '../../components/Share/ShareItems';
import Modal from '../../components/UI/Modal/Modal';
import Spinner from '../../components/UI/Spinner/Spinner';
import HouseImage from '../../components/House/HouseImage/HouseImage';
import Navbar from '../../components/Navigation/Navbar/Navbar';

const StyledShareSection = styled.section`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  animation: ${props => props.theme.animations.fadeIn} 1s linear forwards;

  ${props => props.theme.media.large`
    overflow: hidden;
  `}
`;

const StyledShareSectionInner = styled.div`
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: column;

  ${props => props.theme.media.medium`
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  `}
`;


const StyledLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 1em;
  box-sizing: border-box;
  justify-content: center;
  min-height: 60px;

  ${props => props.theme.media.large`
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: min-content;
    max-width: calc(40% - 1em);
    margin-right: auto;
    margin-bottom: 5em;
    margin-left: auto;
  `}
`;

const StyledHouseBuilderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  z-index: 100;

  ${props => props.theme.media.large`
    height: min-content;
    max-width: calc(50% - 2em);
    padding: 0;
    display: block;
  `}

  ${props => props.theme.height.medium`
    img {
      max-width: 60%;
    }
  `}
`;

const StyledCandyCaneWrapper = styled.div`
  position: absolute;
  left: 10%;
  width: 55%;
  z-index: -1;
  overflow: hidden;
  height: 40%;
  top: 68%;

  ${props => props.theme.media.xlarge`
    left: 25%;
  `}
`;

const StyledShareWrapper = styled.div`
  padding: 2em 0;
  background-color: ${props => props.theme.colors.lightBlue};

  ${props => props.theme.media.large`
    background: none;
  `}
`;

const StyledHouseWrapper = styled.div`
  ${props => props.theme.media.medium`
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
  `}
`;

class Share extends Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();

    let searchParams = decodeURIComponent(window.location.search) || props.history.location.search;
    const query = new URLSearchParams(searchParams);
    let houseImageURL = '';

    for (let param of query.entries()) {
      if (param[1].includes('https://s3.')) {
        houseImageURL = param[1];
      }
    }

    this.state = {
      isDesktop: false,
      shareItems: SHARE_SOCIALS,
      shareInstagram: false,
      houseImageURL: houseImageURL,
      loading: true,
      showImage: false,
      houseImageName: houseImageURL.substring(houseImageURL.lastIndexOf('/')+1),
      twitterShortenURL: null,
      navItems: NAV_MENU,
      navProgress: this.calculateNavProgress(),
      browserName: null,
    };
  }


  componentDidMount() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
    this.loadFacebookJsSDK();
    this.loadTwitterJsSDK();

    const browser = detect();
    this.checkBrowser(browser);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  checkBrowser(browser) {
    const browserVersion = browser.version.split('.');
    const browserNum = browserVersion[0];
    this.setState({browserName: browser.name});

    switch (browser && browser.name) {
      case 'chrome':
        if (browserNum >= '68') { this.setState({ browserName: browser.name }) }
        break;
      case 'edge':
        if (browserNum >= '11') { this.setState({ browserName: browser.name }) }
        break;
      case 'firefox':
        if (browserNum >= '61') { this.setState({ browserName: browser.name }) }
        break;
      case 'safari':
        if (browserNum >= 11) { this.setState({ browserName: browser.name }) }
        break;
      case 'ios':
        this.setState({ browserName: browser.name })
        break;
      case 'android':
        this.setState({ browserName: browser.name })
        break;
      case 'crios':
        this.setState({ browserName: browser.name })
        break;
      case 'fban':
        this.setState({ browserName: browser.name })
        break;
      case 'fbav':
        this.setState({ browserName: browser.name })
        break;
      case 'facebook':
        this.setState({ browserName: browser.name })
        break;
      case 'fxios':
        this.setState({ browserName: browser.name })
        break;
      case 'instagram':
        this.setState({ browserFlag: false })
        break;
      default:
        this.setState({ browserFlag: true })
    }
  }

  resizeHandler = () => {
    this.setState({
      isDesktop: window.innerWidth >= 1024,
    });
  }

  downloadClickHandler = () => {
    if (this.state.browserName === 'crios' || this.state.browserName === 'fxios' || this.state.browserName === 'ios' || this.state.browserName === 'instagram') {
      var win = window.open(this.state.houseImageURL, '_blank');
      win.focus();
    } else {
      download(this.state.houseImageURL);
    }
  }

  shareItemClickHandler = (type) => {
    let socialNetwork = type.toLowerCase();
      if (socialNetwork === 'facebook') {
        this.triggerFacebookShareDialog();
      } else if (socialNetwork === 'instagram') {
        this.setState({shareInstagram: true});
      } else if (socialNetwork === 'download') {
        this.downloadClickHandler();
      }
  }

  instagramShareCancelHandler = () => {
    this.setState({shareInstagram: false});
  }

  navClickHandler = (item, event) => {
    if (item === 'share') {
      event.preventDefault();
    }
  }

  houseImageLoadHandler = () => {
    this.setState({
      loading: false,
      showImage: true,
    });
  }

  joinPartyHandler = () => {
    this.props.history.push({
      pathname: '/watch',
    })
  }

  tourHousesHandler = () => {
    this.props.history.push({
      pathname: '/tour',
    });
  }

  loadFacebookJsSDK() {
    window.fbAsyncInit = function() {
    window.FB.init({
      appId            : '228766111348185',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v3.2'
    });
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
  }

  triggerFacebookShareDialog() {
    window.FB.ui({
      method: 'share',
      href: document.location.href,
      hashtag: '#posturehouseparty',
      quote: 'Check out my gingerbread masterpiece! Join me in supporting a good cause and spread the holiday cheer by constructing your own at posturehouseparty.com',
      mobile_iframe: true,
      redirect_uri: document.location,
    }, function(response){});
  }

  loadTwitterJsSDK() {
    window.twttr = (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function(f) {
        t._e.push(f);
      };

      return t;
    }(document, "script", "twitter-wjs"));


    yourls.connect('https://pst.re/yourls-api.php', {
      signature: '742af396ad'
    });

    yourls.shorten(document.location.href, (result, response) => {
      this.setState({
        twitterShortenURL: result.shorturl,
      });
    });
  }

  calculateNavProgress() {
    const path = this.props.history.location.pathname;
    let progress = 0;

    switch(path) {
      case ('/build'):
        progress = (2 / 3) * 100;
      break;
      case ('/share'):
        progress = (3 / 3) * 100;
      break;
      default:
        progress = 0;
    }
    return progress;
  }




  render() {
    const isDesktop = this.state.isDesktop;
    let houseComponent = null;
    const twitterText = 'Check out my gingerbread masterpiece! ';
    const twitterHashtags = 'posturehouseparty';
    const twitterShareURL = encodeURI(this.state.twitterShortenURL);
    const twitterVia = 'getposture';
    const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(twitterText)}&url=${twitterShareURL}&hashtags=${twitterHashtags}&via=${twitterVia}`;


    if (this.state.houseImageURL) {
        houseComponent = (
          <React.Fragment>
            <StyledHouseWrapper>
              {this.state.loading ? <Spinner /> : ''}
              <HouseImage
                src={this.state.houseImageURL}
                alt={'Finished gingerbread house'}
                load={this.houseImageLoadHandler}
                show={this.state.showImage}/>
            </StyledHouseWrapper>
            <StyledShareWrapper>
              <ShareItems
                items={this.state.shareItems}
                clickedItem={this.shareItemClickHandler}
                link={twitterURL} />
            </StyledShareWrapper>
          </React.Fragment>
        );
    } else {
        houseComponent = (
          <React.Fragment>
            <p style={{color: 'white', textAlign: 'center'}}>Oh no! You tried sharing a house which doesn't exsit yet.</p>
            <p style={{color: 'white', textAlign: 'center'}}>Click on the "Build" button in the navigation bar to get started.</p>
          </React.Fragment>
        );
    }



    let content = (
      <React.Fragment>
        <StyledLogoWrapper>
          <HousePartyLogoHorizontal />
        </StyledLogoWrapper>
        <StyledHouseBuilderWrapper>
          {houseComponent}
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button
              margin="0.5em"
              clicked={this.tourHousesHandler}
            >Tour the houses!</Button>
          </div>
        </StyledHouseBuilderWrapper>
      </React.Fragment>
    );

    if (isDesktop) {
      content = (
        <React.Fragment>
          <StyledLogoWrapper>
            <HousePartyLogo />
            <Button
              margin="0.5em"
              clicked={this.joinPartyHandler}
            >Join the Party!</Button>
            <Button
              margin="0.5em"
              clicked={this.tourHousesHandler}
            >Tour the houses!</Button>
          </StyledLogoWrapper>
          <StyledHouseBuilderWrapper>
            {houseComponent}
          </StyledHouseBuilderWrapper>
          <StyledCandyCaneWrapper>
            <CandyCane />
          </StyledCandyCaneWrapper>
        </React.Fragment>
      );
    }

    return(
      <StyledShareSection>
        <Helmet>
          <title>Share Your House</title>
        </Helmet>
        <Navbar
          progress={this.state.navProgress}
          items={this.state.navItems}
          clicked={this.navClickHandler}/>
        <StyledShareSectionInner>
          <Modal
            show={this.state.shareInstagram}
            modalClosed={this.instagramShareCancelHandler}>
            <p>You’re almost done! Download and save your gingerbread creation and then open instagram to share your image.  Don’t forget to use <b>#posturehouseparty</b> to properly credit your charity of choice!</p>
            <Button
              clicked={this.downloadClickHandler}
            >Download</Button>
          </Modal>
          {content}
        </StyledShareSectionInner>
      </StyledShareSection>
    );
  }
}

export default Share;
