import React from 'react';
import styled from 'styled-components';

import aieFoundationImage from '../../../../../assets/images/foundation/aie-foundation.png';


const StyledAieFoundation = styled.image`
  overflow: visible;
`;

const aieFoundation = ( props ) => {
  return(
    <StyledAieFoundation
      id="aieLogo"
      width="396"
      height="196"
      xlinkHref={aieFoundationImage}
      transform="matrix(0.48 0 0 0.48 373.44 470.4)"
    />
  );
}

export default aieFoundation;
