import React from 'react';
import styled from 'styled-components';

import tropicalBackground from '../../../../../assets/images/tropical-house/tropical-background-thumbnail.png';

const StyledTropicalLocationThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const tropicalLocationThumbnail = ( props ) => {
  return(
      <StyledTropicalLocationThumbnail
        id="tropicalLocationThumbnail"
        src={tropicalBackground}
      />
  );
}

export default tropicalLocationThumbnail;
