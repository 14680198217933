import React from 'react';
import styled from 'styled-components';

import poconoWatersFoundationImage from '../../../../../assets/images/foundation/poconowaters-foundation.png';


const StyledPoconoWatersFoundation = styled.image`
  overflow: visible;
`;

const poconoWatersFoundation = ( props ) => {
  return(
    <StyledPoconoWatersFoundation
      id="poconoWatersLogo"
      width="190"
      height="95"
      xlinkHref={poconoWatersFoundationImage}
      transform="matrix(1 0 0 1 374 470)"
    />
  );
}

export default poconoWatersFoundation;
