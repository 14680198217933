import React from 'react';
import styled from 'styled-components';

import Arrow from '../../Arrow/Arrow.js';

const StyledButton = styled.button.attrs({
  margin: props => props.margin || '1em auto',
})`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.orange};
  padding: 1.5em 4em 1.5em 2em;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 2px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  border: none;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: ${props => props.margin};
  width: 100%;


  &:hover,
  &:focus {
    box-shadow: 0 3px 3px ${props => props.theme.colors.shadow};
    background-color: ${props => props.theme.colors.orangeHover};

    svg {
        animation: ${props => props.theme.animations.bounceRight} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }

  &:active {
    background-color: ${props => props.theme.colors.orangeActive};
  }

  ${props => props.theme.media.largeSmall`
    font-size: 1em;
  `}

  ${props => props.theme.media.medium`
    font-size: 1.2em;
    padding: 1em 4em 1em 2em;
    max-width: 350px;
  `}

  &:disabled {
    background-color: ${props => props.theme.colors.orangeDisabled};
    cursor: not-allowed;
}

  svg {
    max-width: 50px;
    display: inline-block;
    width: 100%;
    margin: 0 1em;
    line-height: 0;
    position: absolute;
    top: 35%;
    right: 0;
  }
`;

const button = ( props ) => {
  return(
    <StyledButton
      onClick={props.clicked}
      margin={props.margin}
      disabled={props.disabled}
    >
      {props.children}
      <Arrow />
    </StyledButton>
  );
}

export default button;
